import { StepConfig } from '../interfaces/setupStep';

// Remove the table page if the setting is to different to fulfillment view.
export const tableConfig: StepConfig = {
  setupShowConfig(metadata) {
    const showTablePickerView = computed(() => metadata.brandSettings?.layout.tableNumber === 'fulfillmentView');

    if (showTablePickerView.value) {
      return RouteName.TABLE;
    }

    return null;
  },
};
