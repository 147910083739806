/* eslint-disable no-unused-vars */
export enum DiscountTypes {
  Product = 1,
  Invoice = 2
}
/* eslint-disable no-unused-vars */
export enum ValueTypes {
  Price = 1,
  Percentage = 2
}
/* eslint-disable no-unused-vars */
export enum RedeemConditionTypes {
  Price = 1,
  Quantity = 2
}

export interface HubsterMoney {
  currencyCode: string;
  amount: number;
}

export interface ProductInfoDto {
  facilityName: string;
  storeId: string;
  categoryName: string;
  productId: string;
  productName: string;
  productPrice: HubsterMoney;
}

export interface RedeemConditionsDto {
  type: RedeemConditionTypes;
  value: number;
}

export interface CouponDiscountsDto {
  discountType: DiscountTypes;
  valueType: ValueTypes;
  value: number;
  productInfo?: ProductInfoDto;
}

export interface ReservedCoupon {
  id: number,
  promoCode: string,
  description: string,
  imageUrl: string,
  conditions: RedeemConditionsDto,
  taskId: string,
}
