<script lang="ts" setup>
import { useActiveElement } from '@vueuse/core';
import { CustomerFormField } from '../interfaces/customer-form-field';

defineProps({
  customerField: {
    type: Object as PropType<CustomerFormField>,
    required: true,
  },
  showError: {
    type: Boolean,
    required: true,
  },
  errorMsg: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['focus', 'accept']);

const showKeyboard = ref(false);
const inputRef = ref<HTMLInputElement>();
const activeElement = useActiveElement();
const model = defineModel<string>({ required: true });

// Close keyboard when user clicks to other input
watch(activeElement, (el) => {
  if (!(el instanceof HTMLInputElement)) return;
  if (el !== inputRef.value && showKeyboard.value) {
    showKeyboard.value = false;
  }
});

function handleFocus() {
  showKeyboard.value = true;
  emit('focus');
}

function handleAccept() {
  showKeyboard.value = false;
  emit('accept');
}

function closeKeyboard() {
  showKeyboard.value = false;
}
</script>

<template>
  <div class="form-container">
    <span class="block ml-2 text-6xl icon" :class="`${customerField.icon}`" />
    <label :for="customerField.key">
      <span class="relative pr-5 text-2xl">
        {{ customerField.label }}
        <span v-if="customerField.required" class="input-required">*</span>
      </span>

      <input
        ref="inputRef"
        class="item-input"
        v-model="model"
        :placeholder="customerField.placeholder"
        :id="customerField.key"
        @focus="handleFocus"
      />

      <p v-if="customerField.info.length > 0" class="input-info">
        <span class="icon icon-info-circle" /> {{ customerField.info }}
      </p>
    </label>
  </div>
  <div v-if="showError" class="label input-error">
    {{ errorMsg }}
  </div>

  <Teleport to="#keyboard">
    <CheckoutKeyboard
      v-if="inputRef"
      v-model="model"
      :show-keyboard="showKeyboard"
      :input-ref="inputRef"
      :type="customerField.type"
      @accept="handleAccept"
      @close="closeKeyboard"
    />
  </Teleport>
</template>

<style scoped>
.form-container {
  @apply shadow-xl flex justify-center items-center gap-8 px-5 py-3 w-full;
}

.input-info {
  @apply flex gap-2 py-3 text-lg italic leading-none label-text-alt text-info;
}

.input-required {
  @apply absolute right-0 text-4xl text-red-500;
}

.input-error {
  @apply text-2xl leading-none label-text-alt text-error;
}

.item-input {
  @apply h-14 w-96 bg-neutral-200 placeholder:text-neutral-300 text-[32px] font-medium leading-[101px] tracking-[-1.263px] px-4 focus:outline-none;
}
</style>
