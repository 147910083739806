<script lang="ts" setup>
import { between, integer, required } from '@vuelidate/validators';

// State management
const showKeyboard = ref(false);
const isTransitioning = ref(false);
const inputRef = ref<HTMLInputElement | null>(null);

// Store controllers
const availableNewStepsController = useAvailableNewStepsController();
const tableController = useTableController();
const { tableNumber } = storeToRefs(tableController);
const gtmEventsV2 = useGTMEventsComposableV2();

// Validation rules
const rules = {
  tableNumber: {
    required,
    integer,
    betweenValue: between(0, 100),
  },
};

const v$ = useVuelidate(rules, { tableNumber });

// Keyboard management
function showKeyboardIfPossible() {
  if (!isTransitioning.value) {
    showKeyboard.value = true;
  }
}

function closeKeyboard() {
  isTransitioning.value = true;
  showKeyboard.value = false;
}

// Form submission
function handleConfirm() {
  if (v$.value.$invalid) return;

  // Trigger GTM event if available
  if (gtmEventsV2?.triggerAddTableNumber) {
    gtmEventsV2.triggerAddTableNumber(tableNumber.value);
  }

  // Go to next step
  availableNewStepsController.nextStep();
}

// Lifecycle hooks
onMounted(() => {
  // Focus the input when component is mounted
  nextTick(() => {
    if (inputRef.value) {
      inputRef.value.focus();
      showKeyboardIfPossible();
    }
  });
});
</script>

<template>
  <div class="mt-11">
    <h1 class="kfc-text text-[30px]">
      {{ tableController.allowQrScanTableNumber ? $t('NUMBER_TABLE_OR_SCAN_QR') : $t('INSERT_TABLE_NUMBER') }}
    </h1>

    <input
      ref="inputRef"
      autocomplete="off"
      v-model="tableNumber"
      class="table-input"
      id="tableNumber"
      min="0"
      max="100"
      placeholder=""
      maxlength="2"
    />
    <Teleport to="#number-keyboard">
      <SlideTransition
        mode="out-in"
        appear
        class="absolute bottom-0 left-0 w-full"
        @after-enter="isTransitioning = false"
        @before-leave="isTransitioning = true"
      >
        <NumbersKeyboard
          v-if="showKeyboard && inputRef"
          class="z-20 w-1/2 p-10 mx-auto"
          v-model="tableNumber"
          :input-ref="inputRef"
          :close-button-hidden="false"
          :max-length="2"
          :invalid-to-continue="v$.$invalid"
          @close="closeKeyboard"
          @confirm="handleConfirm"
        />
      </SlideTransition>
    </Teleport>
  </div>
</template>

<style scoped>
.table-input {
  @apply text-7xl text-center focus:outline-none flex items-center flex-col justify-center h-[100px] w-[220px] py-[36px] px-[40px] border-2 rounded-cards;
  @apply bg-[linear-gradient(129deg,#F8F8F8_2.02%,#F0F0F0_46.38%,#F8F8F8_94.44%)] mx-auto mt-[52px];
}
</style>
