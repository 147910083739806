import { FlowStyles } from '@/modules/common/interfaces/flowStyles';
import { FlowDirections } from '@/modules/common/interfaces/flowDirections';

export const useCheckInController = defineStore('checkIn', () => {
  const metadataStoreV2 = useMetadataStoreV2();
  const { brandSettings } = storeToRefs(metadataStoreV2);

  const isLegacy = computed(() => brandSettings.value?.layout.flowStyle === FlowStyles.Legacy);
  const isHorizontal = computed(() => brandSettings.value?.layout.flowDirection === FlowDirections.Horizontal);

  return { isLegacy, isHorizontal };
});
