import { ItemDescriptor } from '@slabcode/hubster-modifiers-utils';

export function getModifierClass(_item: ItemDescriptor, isGridMode: boolean) {
  const isCountItem = _item.hasNumericInput;
  const counterClass = isCountItem ? '' : 'py-2';
  if (_item.quantity === 0) return isGridMode || isCountItem ? '' : 'py-2';
  const selectedValue = 'modifier-selected';
  if (!isGridMode) return `${selectedValue} ${counterClass}`;
  return selectedValue;
}
