import { RouteName } from '../routes/routeName';

export function useReset() {
  const router = useRouter();
  const route = useRoute();
  const cartStore = useCartStoreV2();
  const webhookStore = useWebhookStoreV2();
  const orderTypeStore = useOrderTypeStore();
  const couponStore = useCouponStore();
  const policiesStore = useLegalPolicies();
  const modalStore = useModalStore();
  const tipsController = useTipsController();
  const currencyController = useCurrencyController();
  const tableController = useTableController();
  const customizeItemStore = useCustomizeItemStore();
  const customerController = useCustomerStore();
  const availableNewStepsController = useAvailableNewStepsController();

  const kioskStatusStore = useKioskStatusStore();
  const kioskSetup = useKioskSetup();

  const customerStoreV2 = useCustomerStoreV2();
  const clientTypeStore = useClientTypeStore();
  const checkoutStepsStore = useCheckoutStepsStore();
  const cancelOrderController = useCancelOrderController();
  const orderActionsStore = useOrderActionsStore();

  function flush() {
    cartStore?.clearCart?.();
    webhookStore?.restartWebhook?.();
    orderTypeStore?.clearOrderType?.();
    couponStore?.clearCoupon?.();
    couponStore?.reset?.();
    policiesStore?.resetPolicies?.();
    tableController?.clear();
    modalStore?.closeModal();
    tipsController.clear();
    currencyController.clear();
    customerController.clear();
    availableNewStepsController.reset();

    // Checkout form reset
    customerStoreV2.reset();
    customerStoreV2.$dispose();
    clientTypeStore.reset();
    clientTypeStore.$dispose();
    checkoutStepsStore.reset();

    customizeItemStore.finishCustomization();
    customizeItemStore.$dispose();
  }

  async function flushState() {
    console.warn('Reset from:', route.name);
    // Send webhook to logs
    cancelOrderController.cancelOrder(orderActionsStore.webhookDto);

    flush();

    router.replace({ name: RouteName.WELCOME });

    // If the WL update the kiosk metadata, call again when end order
    if (kioskStatusStore.autoUpdate) {
      await kioskSetup.setupMetadata();
    }
  }

  return {
    flushState,
  };
}
