<script setup lang="ts">
// TODO: Rename this component
const modalStore = useModalStore();

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: false,
    default: '',
  },
  successButtonLabel: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: false,
    default: null,
  },
  /**
   * Define if its an store state or local state
   */
  localModal: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits([
  'onOk',
  /**
   * This emit is used when the modal has an localState instead of store state
   */
  'onClose',
]);

function closeModal() {
  if (!props.localModal) {
    modalStore.closeModal();
    return;
  }
  emit('onClose');
}
</script>
<template>
  <KioskBorderlessModal
    modal-class="!overflow-visible !max-w-[580px] w-full"
    :modal-header="false"
  >
    <template #default>
      <template v-if="icon">
        <KioskImage
          :alt="title"
          :src="icon"
          image-class="w-[400px] h-auto absolute top-[-150px] left-1/2 -translate-x-1/2"
          hide-border
        />
      </template>

      <section class="flex flex-col items-center justify-center gap-3 pb-10 pt-[180x]">
        <h2 class="uppercase font-big min-h-[60px] text-center flex items-center">
          {{ title }}
        </h2>

        <p v-if="message" class="modal-text">
          {{ message }}
        </p>
      </section>
    </template>
    <template #footer>
      <div class="flex justify-center w-full gap-5">
        <KioskButton
          color="primary"
          class="!w-full !max-w-none !h-[92px]"
          text-size="medium"
          type="button"
          @on-click="$emit('onOk')"
        >
          {{ successButtonLabel }}
        </KioskButton>
      </div>
    </template>
    <template #close-button>
      <KioskButton
        type="button"
        color="outline"
        class="!rounded-full !border-0 !w-[80px] !h-[80px]"
        @on-click="closeModal()"
      >
        <span class="icon icon-clear" />
      </KioskButton>
    </template>
  </KioskBorderlessModal>
</template>

<style scoped>
.modal-text {
  @apply text-[40px] leading-10 font-normal text-center;
}
</style>
