<script lang="ts" setup>
import { showFormKeyError } from '@common/utils';
import { CustomerFieldsDto } from '@slabcode/kiosks-core/dtos/settings/cluster';
import { useVuelidate } from '@vuelidate/core';
import CheckoutInput from './CheckoutInput.vue';
import { ExposeFormFields } from '../interfaces/expose-form-fields';

const customerStore = useCustomerStoreV2();
const { customer, rules } = storeToRefs(customerStore);
const v$ = useVuelidate(rules, customer);

const formRef = ref<HTMLFormElement>();

function focusFirstInvalidInput() {
  if (!formRef.value) return;
  const collection = Array.from(formRef.value?.querySelectorAll('input'));
  const firstInvalidInput = collection.find((input) => {
    const key = input.id;
    return v$.value[key].$invalid;
  });

  if (firstInvalidInput) {
    firstInvalidInput.focus();
  }
}

function handleCollapseFinishAnimation() {
  focusFirstInvalidInput();
}

function handleNext() {
  focusFirstInvalidInput();
}

function isInvalid(key: keyof CustomerFieldsDto) {
  if (!v$.value[key]) return false;
  return v$.value[key].$invalid && v$.value[key].$dirty;
}

onMounted(() => {
  focusFirstInvalidInput();
});

defineExpose<ExposeFormFields>({
  invalid: computed(() => v$.value.$invalid ?? false),
  handleCollapseFinishAnimation,
});
</script>

<template>
  <form ref="formRef" class="custom-input-container">
    <template v-for="customerField in customerStore.customerFields">
      <CheckoutInput
        v-if="customerField.enabled"
        v-model="v$[customerField.key].$model"
        :customer-field="customerField"
        :key="customerField.key"
        :show-error="isInvalid(customerField.key)"
        :error-msg="showFormKeyError(v$, customerField.key)"
        @accept="handleNext"
      />
    </template>
  </form>
</template>

<style scoped>
.custom-input-container {
  @apply flex flex-col mx-auto py-10 justify-center gap-2 w-[556px] bg-white;
}
</style>
