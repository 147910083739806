<script lang="ts" setup>
const invoiceCouponStore = useInvoiceCouponStore();
const cartStore = useCartStoreV2();
const cartSummary = useCartSummaryStore();
const { getFormatPrice } = useTotal();

defineProps({
  price: {
    type: String,
    required: true,
  },
});

</script>

<template>
  <template v-if="cartStore.subtotal > 0">
    <div class="flex flex-col items-center" v-if="invoiceCouponStore.invoiceCoupon">
      {{ getFormatPrice(cartSummary.totalWithDiscounts) }}
      <small class="text-xl text-kiosk-primary">{{ $t('SAVED') }} {{ getFormatPrice(cartSummary.difference) }}</small>
    </div>
    <template v-else>
      {{ price }}
    </template>
  </template>
</template>
