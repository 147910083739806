import { RouteName } from '@common/routes/routeName';

import { RouteRecordRaw } from 'vue-router';

import CheckInWrapper from './components/CheckInWrapper.vue';

export const checkInRoutes: RouteRecordRaw[] = [
  {
    path: 'check-in',
    name: RouteName.CHECK_IN,
    component: CheckInWrapper,
  },
];
