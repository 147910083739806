import { DefaultItemDescriptor,
  ItemDescriptor,
  ModifierDescriptor,
  defaultItemTotalHandler,
  defaultItemValidator } from '@slabcode/hubster-modifiers-utils';
import { actionsFactory } from '@lib/factories';

export function itemFactory(
  itemId: string,
  parent?: ModifierDescriptor,
): ItemDescriptor {
  return new DefaultItemDescriptor(
    itemId,
    actionsFactory,
    defaultItemTotalHandler,
    defaultItemValidator,
    parent,
  );
}
