export function useValidators() {
  const { t } = useI18n();

  const required$ = (field: string) => helpers.withMessage(t('USER_INFO.VALIDATIONS.REQUIRED', {
    field,
  }), required);

  const minLength$ = (field: string, length: number) => helpers.withMessage(t('USER_INFO.VALIDATIONS.MIN_LENGTH', {
    field,
    length,
  }), minLength(length));

  const alpha$ = helpers.withMessage(t('USER_INFO.VALIDATIONS.ALPHA'), (value: string) => /^[a-zA-Z\s\u00F1\u00D1]*$/.test(value));
  const email$ = helpers.withMessage(t('USER_INFO.VALIDATIONS.EMAIL'), email);
  const maxLength$ = (field: string, length: number) => helpers.withMessage(t('USER_INFO.VALIDATIONS.MAX_LENGTH', {
    field,
    length,
  }), maxLength(length));

  return {
    required$,
    minLength$,
    maxLength$,
    alpha$,
    email$,
  };
}
