import { DefaultItemDescriptor,
  ItemDescriptor,
  defaultItemTotalHandler,
  defaultItemValidator } from '@slabcode/hubster-modifiers-utils';
import { actionsFactory } from '@lib/factories';

export function rootFactory({ itemId }: { itemId: string }): ItemDescriptor {
  return new DefaultItemDescriptor(
    itemId,
    actionsFactory,
    defaultItemTotalHandler,
    defaultItemValidator,
  );
}
