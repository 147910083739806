/* eslint-disable no-unused-vars */
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { ReservedCoupon, ValueTypes } from './reservedCoupon';

export const enum CouponResponseType {
  VALID = 'valid',
  INVALID = 'invalid'
}

export interface ProductCoupon {
  item: HubsterMenuPublishItem;
  originalItemPrice: number;
}

export interface InvoiceCoupon {
  valueType: ValueTypes;
  value: number;
}

export interface CouponResponseMessage {
  title: string;
  description: string
}

export interface CouponResponseInfo {
  reservedCoupon: ReservedCoupon;

  // When is 'invalid' not bring couponInfo
  couponInfo?: ProductCoupon | InvoiceCoupon;
}

export interface CouponResponse {
  result: CouponResponseType;
  message: CouponResponseMessage;
  contents?: CouponResponseInfo;
}
