<script lang="ts" setup>
defineProps({
  disabledLeftButton: {
    type: Boolean,
    required: true,
  },
  mainLblAction: {
    type: String,
    required: true,
  },
  secondaryLblAction: {
    type: String,
    required: true,
  },
});

// Emit events
defineEmits<{
  save: [];
}>();

const cartStore = useCartStoreV2();
const cartSummary = useCartSummaryStore();
const metadataStoreV2 = useMetadataStoreV2();
const { clusterSettings } = storeToRefs(metadataStoreV2);
const tipsController = useTipsController();
const { tips, tipSelected, hasGamification } = storeToRefs(tipsController);

function toggleTip(option: number) {
  if (tipSelected.value === option) {
    tipSelected.value = 0;
    return;
  }
  tipSelected.value = option;
}

function getImageUrl(index: number) {
  return new URL(`/src/assets/images/Coin${index + 1}.webp`, import.meta.url).href;
}

function calculateTip(tip: number) {
  return tipsController.calculateTip(tip, cartSummary.invoiceDiscount, cartStore.subtotal);
}
</script>

<template>
  <div class="flex flex-col items-end justify-between w-full">
    <section class="flex flex-col items-center justify-center w-full gap-11">
      <header class="flex flex-col items-center gap-3 text-center max-w-[585px]">
        <h2 class="text-[40px] text-balance font-medium text-center leading-10 uppercase">
          {{ $t('TIPS.MODAL_TITLE') }}
        </h2>
        <p class="text-3xl text-center text-gray-600">
          {{ $t('TIPS.MODAL_DESCRIPTION') }}
        </p>
      </header>
      <article class="grid grid-cols-2 gap-8">
        <template v-for="(option, index) in tips" :key="option">
          <label
            :tabindex="option"
            :class="[
              'lbl-bg',
              tipSelected === option
                ? 'border-kiosk-btnPrimaryBg shadow-lg'
                : 'border-[#D2CFCF]',
            ]"
            @click="toggleTip(option)"
            @keydown.enter="toggleTip(option)"
            @keydown.space="toggleTip(option)"
          >
            <input
              type="radio"
              v-model="tipSelected"
              class="absolute inset-0 opacity-0 cursor-pointer"
              :value="option"
            />
            <div class="text-center">
              <div class="flex items-center justify-center h-[68px] text-5xl font-medium">
                <img
                  v-if="hasGamification"
                  :src="getImageUrl(index)"
                  :alt="option.toString()"
                  class="w-[68px] h-[68px] bg-contain"
                />
                <span v-else> {{ option }}%</span>
              </div>
              <span
                class="block text-3xl text-nowrap"
                :class="{
                  'font-medium': tipSelected === option,
                }"
              >
                $ {{ calculateTip(option) }}
              </span>
              <span class="text-xl text-gray-500">
                {{ clusterSettings?.currency.preferredCurrencyCode }}
              </span>
            </div>
          </label>
        </template>
      </article>
    </section>
    <footer class="flex items-end justify-between w-full gap-8 mt-10">
      <KioskButton
        @click="$emit('save')"
        :disabled="disabledLeftButton && tipSelected !== 0"
        text-size="small"
        class="!w-full !px-5"
      >
        {{ secondaryLblAction }}
      </KioskButton>
      <KioskButton
        @click="$emit('save')"
        :disabled="!tipSelected"
        color="primary"
        text-size="small"
        class="!w-full !px-5"
      >
        {{ mainLblAction }}
      </KioskButton>
    </footer>
  </div>
</template>

<style scoped>
.lbl-bg {
  @apply bg-[linear-gradient(129deg,#F8F8F8_2.02%,#F0F0F0_46.38%,#F8F8F8_94.44%)] relative transition-all;
  @apply flex items-center justify-center h-[204px] w-[204px] py-[30px] px-[40px] border-2 rounded-cards cursor-pointer;
}
</style>
