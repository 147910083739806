<script lang="ts" setup>
import { LanguageLocales } from '@/modules/language/types';

const props = defineProps({
  hasFather: {
    type: Boolean,
    default: false,
  },
});

const bannersViewController = useBannersViewController();
const languageStore = useLanguageStore();
const { languages, currentLanguage } = storeToRefs(languageStore);

const emit = defineEmits(['selectLanguage']);

function selectLanguage(langId: LanguageLocales) {
  if (props.hasFather) emit('selectLanguage', langId);
  else bannersViewController.startOrderLanguage(langId);
}
</script>

<template>
  <div>
    <h2 class="mb-10 font-medium text-[40px] uppercase">
      {{ $t('WELCOME') }}
    </h2>
    <p class="mb-8 font-medium text-[30px] uppercase">
      {{ $t('CHECK_IN.CHOOSE_LANGUAGE') }}
    </p>

    <!-- language -->
    <div class="flex items-center justify-center gap-12 pb-2 mt-8 h-28">
      <button
        type="button"
        v-for="lang in languages"
        :key="lang.id"
        @click="selectLanguage(lang.id)"
        :class="[
          'overflow-hidden w-16 h-16 transition-all rounded-full border-2 border-gray-400 hover:border-kiosk-btnPrimaryBg',
          {
            'border-kiosk-btnPrimaryBg scale-[1.8] transition-all':
              lang.id === currentLanguage,
          },
        ]"
      >
        <img
          :src="`/flags/${lang.id}.png`"
          :alt="lang.label"
          class="object-cover w-full h-full"
        />
      </button>
    </div>
  </div>
</template>
