<script lang="ts" setup>
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { CheckoutSteps } from '@/modules/checkout-migration/constants/checkout-steps';

defineProps({
  collapse: {
    type: Boolean,
    required: true,
  },
});

const checkoutStepsStore = useCheckoutStepsStore();
const customerStore = useCustomerStoreV2();
const orderTypeStore = useOrderTypeStore();
const { customer } = storeToRefs(customerStore);

const openConfirmModal = ref(false);
const showPaymentModal = computed(() => {
  if (orderTypeStore.createOrderType === PaymentMethod.UNKNOWN) return false;
  return openConfirmModal.value;
});

function handleOpenModal() {
  openConfirmModal.value = true;
}
</script>

<template>
  <CollapsableContainer
    :model-value="collapse"
    :title="$t('CHECKOUT.SUMMARY_TITLE')"
    :show-collapse="false"
    :position="checkoutStepsStore.getStepIndex(CheckoutSteps.PREVIEW)"
  >
    <!-- <Payment :customer="customer" /> -->

    <InvoicePreview :customer-name="customer.name" />
    <PaymentMethods @open-modal="handleOpenModal()" />
  </CollapsableContainer>

  <TeleportAnimationModal :show="showPaymentModal">
    <PaymentModalConfirm @close-modal="openConfirmModal = false" />
  </TeleportAnimationModal>
</template>
