<script lang="ts" setup>
import { CssVariables } from '@common/enums/cssVariables';

const cssVariablesUtils = useCssVariable();
const metadataStoreV2 = useMetadataStoreV2();
const { brandSettings } = storeToRefs(metadataStoreV2);

defineProps({
  type: {
    type: String as PropType<'button' | 'submit'>,
    required: false,
    default: 'button',
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  icon: {
    type: String,
    required: false,
    default: '',
  },
  textSize: {
    type: String as PropType<'extra-small' | 'small' | 'base' | 'medium' | 'large'>,
    required: false,
    default: 'medium',
  },
  color: {
    type: String as PropType<'primary' | 'outline' | 'ghost' | 'gradient'>,
    required: false,
    default: 'outline',
  },
  twClass: {
    type: String,
    required: false,
    default: '',
  },
});

const colorGradient1 = ref<string>('');
const colorGradient2 = ref<string>('');

onMounted(() => {
  const neutral = cssVariablesUtils.changeBrightness(CssVariables.PRIMARY, 20);
  const darken = cssVariablesUtils.changeBrightness(CssVariables.PRIMARY, -100);
  colorGradient1.value = neutral;
  colorGradient2.value = darken;
});

defineEmits(['onClick']);
</script>

<template>
  <button
    :class="[
      'kiosk-btn',
      `kiosk-btn-${textSize}`,
      `kiosk-btn-${color}`,
      { 'py-[30px]': !icon.length && textSize !== 'small' },
      twClass,
    ]"
    :type="type"
    :disabled="disabled"
    v-bind="$attrs"
    @click="$emit('onClick', $event)"
  >
    <span
      v-if="icon.length && brandSettings?.buttons.showIcons"
      class="block mr-4 icon"
      :class="`icon-${icon}`"
    />

    <slot />
  </button>
</template>

<style scoped>
.kiosk-btn {
  @apply h-auto flex flex-nowrap px-8 border-[3px] items-center justify-center uppercase rounded-btn shadow-md disabled:opacity-50;
  font-weight: var(--kiosk-btn-font-weight);
}

.kiosk-btn-large {
  @apply py-6 min-w-[444px] leading-[44px] text-[52px];
}

.kiosk-btn-medium {
  @apply py-5 max-w-[444px] leading-10 text-[40px];
}

.kiosk-btn-base {
  @apply py-4 px-6 border-2 leading-8 text-[36px];
}

.kiosk-btn-small {
  @apply py-4 px-6 border-2 leading-8 text-[26px];
}

.kiosk-btn-extra-small {
  @apply py-2.5 px-4 border-2 leading-6 text-xl;
}

.kiosk-btn-primary {
  @apply bg-kiosk-btnPrimaryBg text-kiosk-btnPrimaryText border-kiosk-btnPrimaryBg;
}

.kiosk-btn-ghost {
  @apply bg-kiosk-btnGhostBg text-kiosk-btnGhostText border-none shadow-none;
}

.kiosk-btn-outline {
  @apply bg-white text-kiosk-btnPrimaryBg border-kiosk-btnPrimaryBg;
}

.kiosk-btn-gradient, .gradient-enabled .kiosk-btn-primary {
  @apply text-kiosk-btnPrimaryText/95 border-none;
  background: linear-gradient(123deg, v-bind(colorGradient1), v-bind(colorGradient2));
}
</style>
