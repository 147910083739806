import { HubsterMenuModifierGroup } from '@slabcode/hubster-models/hubster/common/menu';
import { DefaultModifierDescriptor,
  ItemDescriptor,
  ModifierDescriptor,
  defaultModifierValidator } from '@slabcode/hubster-modifiers-utils';
import { itemFactory } from '@lib/factories';

export function modifierFactory(
  modifier: HubsterMenuModifierGroup,
  parent: ItemDescriptor,
): ModifierDescriptor {
  return new DefaultModifierDescriptor(
    modifier,
    defaultModifierValidator,
    parent,
    itemFactory,
  );
}
