<script lang="ts" setup>
import { between, integer, required } from '@vuelidate/validators';
import { useEventListener } from '@vueuse/core';

const showKeyboard = ref(false);
const inputRef = ref<HTMLInputElement | null>(null);

const availableStepsController = useAvailableStepsController();
const tableController = useTableController();
const { tableNumber } = storeToRefs(tableController);

const rules = {
  tableNumber: {
    required,
    integer,
    betweenValue: between(0, 100),
  },
};

const v$ = useVuelidate(rules, { tableNumber });

useEventListener('paste', (event) => {
  event.preventDefault();
  const pastedData = event.clipboardData?.getData('text');
  if (pastedData) {
    tableNumber.value = pastedData.replace(/\D/g, '');
  }
});

function pickupAtCounter() {
  tableController.pickUpAtCounter({
    // Make redirect
    next: () => availableStepsController.nextStep(),
  });
}
</script>

<template>
  <div>
    <span class="kfc-text text-[40px]">{{ $t("INSERT_TABLE_NUMBER") }}</span>

    <div
      class="flex gap-2 flex-col justify-center bg-white items-center shadow-2xl rounded-xs p-10 w-[440px]"
    >
      <div class="flex gap-5 items-center">
        <label for="tableNumber">
          <span class="icon icon-table-bar mr-5 text-[60px]" />
        </label>

        <input
          autocomplete="off"
          v-model="tableNumber"
          class="table-input"
          id="tableNumber"
          min="0"
          max="100"
          placeholder="00"
          maxlength="2"
          ref="inputRef"
          @focus="showKeyboard = true"
        />
      </div>
      <small
        v-if="tableController.allowQrScanTableNumber"
        class="text-lg text-gray-500 text-balance"
      >
        {{ $t("SCAN_QR_OR_TABLE_NUMBER") }}
      </small>
    </div>

    <div class="flex flex-col gap-10 items-center">
      <KioskButton
        :disabled="v$.$invalid"
        class="next-button"
        color="primary"
        @click="availableStepsController.nextStep()"
      >
        {{ $t("NEXT") }}
      </KioskButton>

      <template v-if="tableController.displayPickupAtCounter">
        <div class="separator w-[346px] h-0.5 bg-neutral-400 my-4" />

        <KioskButton
          text-size="large"
          class="option-button"
          @click="pickupAtCounter()"
        >
          {{ $t("PICK_UP_COUNTER") }}
        </KioskButton>
      </template>
    </div>

    <SlideTransition enter-animation="fadeInUp" leave-animation="fadeOutDown">
      <NumbersKeyboard
        v-if="showKeyboard"
        class="absolute bottom-0 z-20 p-10 w-1/2"
        v-model="tableNumber"
        :input-ref="inputRef"
        :max-length="2"
        @close="showKeyboard = false"
      />
    </SlideTransition>
  </div>
</template>
<style scoped>
.table-input {
  @apply bg-neutral-200 text-7xl text-center w-full focus:outline-none;
}

.next-button {
  @apply w-[420px] h-[120px] p-5 text-5xl flex items-center justify-center disabled:bg-opacity-30;
}

.option-button {
  @apply !min-w-[420px] max-w-[420px] !leading-[50px] text-5xl mb-5;
}
</style>
