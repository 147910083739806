<script lang="ts" setup>
const fulfillmentController = useFulfillmentController();
const orderTypeStore = useOrderTypeStore();
const metadataStoreV2 = useMetadataStoreV2();
const kioskInfo = useKioskInfoStore();
const showKfcImages = ref(false);

onMounted(() => {
  /**
   * NOTE: This is temporary solution to show KFC images for KFC-Kiosk
   * while the client approves the new fulfillment design.
   */
  if (kioskInfo.integration === 'KFC-Kiosk') {
    showKfcImages.value = true;
  }
});

const { brandSettings } = storeToRefs(metadataStoreV2);
const { t } = useI18n();
</script>

<template>
  <InactiveWrapper>
    <div class="menu-container">
      <button
        v-for="(option, index) in orderTypeStore.orderOptions"
        type="button"
        :class="['fulfillment-button rounded-cards']"
        :key="index"
        :icon="option.icon"
        @click="fulfillmentController.redirectFromFulfillment(option.value)"
      >
        <template v-if="brandSettings?.buttons.showIcons">
          <span v-if="!showKfcImages" :class="`icon icon-${option.icon} text-7xl`" />
          <img v-else :src="option.alternativeKfcImg" :alt="$t(option.label)" />
        </template>
        {{ t(option.label) }}
      </button>
    </div>
  </InactiveWrapper>
</template>

<style scoped>
.menu-container {
  @apply flex flex-col items-center gap-12 absolute top-0 w-full justify-center h-full flex-wrap;
}

.fulfillment-button {
  @apply flex flex-col items-center justify-center gap-5 bg-white text-4xl text-kiosk-primary rounded-xl p-8 border-2 border-kiosk-primary w-[480px] h-[292px];
}

.back-button {
  @apply flex mt-20 flex-col items-center justify-center gap-5 bg-white text-4xl text-kiosk-primary rounded-full p-8 border-2 border-kiosk-primary w-[150px] h-[150px];
}
</style>
