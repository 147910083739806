import { HubsterOrderItem } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';
import { ItemDescriptor } from '@slabcode/hubster-modifiers-utils';

export function parseItems(items: ItemDescriptor[]): HubsterOrderItem[] {
  return items.map((item) => ({
    quantity: item.quantity,
    id: item.itemMeta.id,
    name: item.itemMeta.name,
    price: item.itemMeta.price.amount,
    modifiers: parseModifiers(item.modifiers),
    categoryId: item.category.id,
    categoryName: item.category.name,
  }));
}
