<script lang="ts" setup>
import { useScroll } from '@vueuse/core';
import { FadeColors } from '@common/enums/fadeColors';
import { getProductImage } from '@common/utils';
import { useCustomize } from '../composables/useCustomize';
import { useItemActionEvent } from '../composables/useItemActionEvent';

const { getFormatPrice } = useTotal();
const {
  item: currentItem,
  filteredModifiers,
  pushCustomization,
  saveCustomization,
  cancelCustomization,
  getModifierClass,
  checkUpgrade,
  acceptUpgrade,
  rejectUpgrade,
  shouldDisplayUpgrade,
  finishCustomization,
  filterItemsWithAlcohol,
  abortCustomization,
} = useCustomize();

const {
  selectItem,
  increaseItemQuantity,
  decreaseItemQuantity,
  shouldDisplayAlcoholInput,
  enableAlcohol,
  disableAlcohol,
  showMaximumAnimation,
} = useItemActionEvent();

const menu = useMenuStore();
const { productsCurrency } = storeToRefs(menu);
const customizeItemStore = useCustomizeItemStore();
const { modifierStack, upgradeModifier, isEditing, item } = storeToRefs(
  customizeItemStore,
);
const metadataStoreV2 = useMetadataStoreV2();
const { priceDecimals } = storeToRefs(metadataStoreV2);
const stepIndex = ref(0);
const currentModifier = ref(filteredModifiers[stepIndex.value]);
const shouldDisplayExitConfirmation = ref<boolean>(false);
const { t } = useI18n();

const confirmedSteps = ref([...Array(filteredModifiers.length).fill(false)]);

const steps = computed(() => {
  const modifiersLength = filteredModifiers.length || 0;
  if (modifiersLength === 0) return [];

  return filteredModifiers.map((m, i) => {
    let image = '';
    const selectedItem = m.items.find((it) => it.selected);

    if ((stepIndex.value > i && selectedItem) || confirmedSteps.value[i]) {
      image = selectedItem?.itemMeta.photoIds[0] ?? '';
    }

    return {
      label: m.modifierMeta.description ?? '',
      value: i + 1,
      image,
    };
  });
});

const productHeaderImage = computed(() => {
  if (!currentItem) return '';
  return getProductImage(currentItem.itemMeta.photoIds);
});

const isLastStep = computed(
  (): boolean => stepIndex.value === (filteredModifiers.length || 1) - 1,
);

function formatPrice(total: number) {
  const price = formatCurrency(productsCurrency.value, total, priceDecimals.value);
  if (!isLastStep.value) return `${t('NEXT')} | ${price}`;
  const result = isEditing.value ? t('SAVE') : t('ADD');
  return `${result} | ${price}`;
}

function submit({ add = true }: { add?: boolean } = {}) {
  if (add) {
    finishCustomization();
  } else {
    abortCustomization();
  }
}

function nextHandler(): void {
  if (!isLastStep.value) {
    confirmedSteps.value[stepIndex.value] = true;
    confirmedSteps.value[stepIndex.value + 1] = false;
    currentModifier.value = filteredModifiers[(stepIndex.value += 1)];
    return;
  }

  if (checkUpgrade()) {
    return;
  }

  submit();
}

function prevHandler(): void {
  const newIndex = stepIndex.value - 1;
  stepIndex.value = newIndex;
  confirmedSteps.value[newIndex] = false;
  currentModifier.value = filteredModifiers[newIndex];
}

function exit() {
  shouldDisplayExitConfirmation.value = false;
  submit({ add: false });
}

function dismissUpgrade() {
  rejectUpgrade();
  submit();
}

function confirmUpgrade() {
  acceptUpgrade();
  submit();
}

function goToStep(index: number) {
  if (!currentModifier.value.valid) return;

  if (index > stepIndex.value) {
    // Confirm defaults
    [...Array(index - stepIndex.value).keys()].forEach(() => nextHandler());
  } else {
    currentModifier.value = filteredModifiers[index];
  }

  stepIndex.value = index;
  confirmedSteps.value[index] = false;
}

const wrapper = ref();
const { arrivedState } = useScroll(wrapper);

// const disabledNext = computed(() => (isLastStep.value ? !currentItem?.valid : !currentModifier.value?.valid));
</script>

<template>
  <section class="h-screen customization">
    <div
      v-if="item"
      class="grid items-center grid-cols-4 gap-10 z-20 h-[17rem] px-10 py-4 border-b shadow-md current-item border-neutral-200/40"
    >
      <KioskImage
        :src="productHeaderImage"
        :alt="item.itemMeta.name"
        hide-border
        image-class="object-cover h-full"
      />

      <div class="col-span-3 item-description">
        <h4 class="text-4xl font-bold">
          {{ item.itemMeta.name }}
        </h4>

        <p class="text-2xl text-neutral-400">
          {{ item.itemMeta.description }}
        </p>
      </div>
    </div>

    <section class="flex modifiers-customization grow">
      <div
        class="flex flex-col items-center h-full gap-4 py-16 border-r shadow-md max-w-56 min-w-56 steps"
      >
        <div
          v-for="(step, index) in steps"
          :key="step.value"
          class="flex flex-col items-center justify-center w-full gap-4 px-3"
          :class="{ active: stepIndex > index }"
        >
          <div
            v-if="stepIndex > index || confirmedSteps[index]"
            :class="['relative step-image', { 'w-full': !step.image }]"
          >
            <button v-if="step.image" type="button" @click="goToStep(index)">
              <KioskImage
                class="w-20 h-20"
                :class="{ 'opacity-50': !currentModifier.valid }"
                :src="step.image"
                hide-border
                loader-size="small"
              >
                {{ step.image }}
              </KioskImage>
            </button>

            <div v-else class="flex items-center justify-center w-full h-20">
              <!-- TODO: Validar cuando es opcional, y tiene descripción para que se vea igual que los botones de abajo -->
              <!-- TODO: Validar el class  -->
              <KioskButton
                text-size="extra-small"
                class="my-4"
                color="outline"
                :class="step.label ? 'w-full' : '!w-12 !h-12 !p-0'"
                :disabled="!currentModifier?.valid"
                @click="goToStep(index)"
              >
                {{ step.label.length ? step.label : index + 1 }}
              </KioskButton>
            </div>

            <div
              class="absolute top-0 right-0 flex items-center justify-center w-8 h-8 text-2xl text-white rounded-full bg-kiosk-primary"
            >
              <span class="icon icon-check" />
            </div>
          </div>

          <KioskButton
            v-else
            text-size="extra-small"
            class="my-4"
            :class="step.label ? 'w-full' : '!w-12 !h-12 !p-0'"
            :color="stepIndex === index ? 'primary' : 'outline'"
            :disabled="!currentModifier?.valid"
            @on-click="goToStep(index)"
          >
            {{ step.label.length ? step.label : index + 1 }}
          </KioskButton>

          <div
            v-if="index + 1 < steps.length"
            :class="['step-separator', { active: stepIndex > index }]"
          />
        </div>
      </div>

      <div class="relative flex flex-col bg-white modifier-items">
        <ModifierCounter
          class="px-6"
          v-model="showMaximumAnimation"
          :modifier="currentModifier"
          :count="customizeItemStore.modifierSelections(currentModifier)"
        />

        <div class="relative grow">
          <FadeScroll
            :color="FadeColors.WHITE"
            position="top"
            :show="!arrivedState.top"
          />

          <div ref="wrapper" class="overflow-y-auto modifier-container">
            <template
              v-for="itemMod in filterItemsWithAlcohol(currentModifier.items)"
              :key="itemMod.itemMeta.id"
            >
              <GridItem
                :item="itemMod"
                :container-class="getModifierClass(itemMod, true)"
                :modifier-decimals="priceDecimals"
                @select="selectItem"
                @customize="pushCustomization"
                @add="increaseItemQuantity($event)"
                @sub="decreaseItemQuantity($event)"
                :display-checkbox="
                  currentModifier.dynamicMaxPerModifierSelectionQuantity === 1
                    && currentModifier.dynamicMaximumSelections > 1
                "
              />
            </template>
          </div>

          <FadeScroll
            :color="FadeColors.WHITE"
            position="bottom"
            :show="!arrivedState.bottom"
          />
        </div>
      </div>
    </section>

    <OrderBottomActionsV2
      back-icon=""
      :back-label="$t('BACK')"
      :next-label="formatPrice(item?.total ?? 0)"
      :disable-back="stepIndex === 0 || !currentModifier.valid"
      :disable-next="isLastStep ? !currentItem?.valid : !currentModifier?.valid"
      @next-pressed="nextHandler"
      @back-pressed="prevHandler"
    >
      <template #prepend>
        <button
          type="button"
          class="footer-back-btn"
          @click="shouldDisplayExitConfirmation = true"
        >
          <span class="text-4xl icon icon-home" />
          <span class="text-xl font-light">{{ $t('GO_BACK') }}</span>
        </button>
      </template>
    </OrderBottomActionsV2>

    <TeleportAnimationModal :show="!!(upgradeModifier && shouldDisplayUpgrade)">
      <UpSellModal
        :icon="upgradeModifier!.items[0].itemMeta.photoIds[0]"
        :title="upgradeModifier!.items[0].itemMeta.name"
        :message="upgradeModifier!.items[0].itemMeta.description"
        :local-modal="true"
        :success-button-label="`${$t('ORDER.ACCEPT_UPGRADE')} + ${getFormatPrice(
          upgradeModifier!.items[0].itemMeta.price.amount,
        )}`"
        @on-close="dismissUpgrade"
        @on-ok="confirmUpgrade"
      />
    </TeleportAnimationModal>

    <ConfirmModal
      :show-modal="shouldDisplayExitConfirmation"
      :title="$t('MODIFIERS_BACK_TITLE')"
      :message="$t('MODIFIERS_BACK_DESCRIPTION')"
      :local-modal="true"
      :success-button-label="$t('TIMELINE.CONFIRM')"
      :deny-button-label="$t('CANCEL')"
      @on-close="shouldDisplayExitConfirmation = false"
      @on-ok="exit"
    />

    <ModifierDialog
      v-for="modElement in modifierStack"
      :key="modElement.item.itemMeta.id"
      :parent-item="modElement.item!"
      @save="saveCustomization"
      @cancel="cancelCustomization"
    />

    <AlcoholConfirm
      :show-modal="shouldDisplayAlcoholInput"
      modifiers
      @cancel="disableAlcohol"
      @confirm="enableAlcohol"
    />
  </section>
</template>

<style scoped>
.customization {
  @apply flex flex-col overflow-hidden;
}

.shake {
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.customization-button {
  @apply flex gap-1 items-center justify-center w-[308px] !h-16 py-3 text-3xl;
}

.modifier-container {
  @apply py-6 px-6 grid grid-cols-3 max-h-[1400px] gap-8 place-content-start justify-items-center mb-5;
}

.optional-label {
  @apply text-neutral-400 bg-neutral-100 font-bold px-1 text-4xl rounded h-10 flex justify-center items-center;
}

.counter-span {
  @apply flex justify-center items-start w-20 h-10 leading-10 text-white border-none rounded px-1 font-bold;
}

.step-separator {
  @apply w-0.5 h-16 relative bg-gray-300;
}

.step-separator::before,
.step-separator::after {
  @apply content-[''] absolute rounded-full w-1 h-1 bg-gray-300 left-1/2 top-full -translate-x-1/2;
}

.active.step-separator {
  @apply bg-kiosk-primary;
}
.active.step-separator::before,
.active.step-separator::after {
  @apply bg-kiosk-primary;
}

.step-separator::before {
  @apply top-[unset] bottom-full;
}

.actions button {
  @apply !py-3.5 max-w-[444px] text-3xl;
}
</style>
