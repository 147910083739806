<script setup lang="ts">
import { PropType } from 'vue';
import { useScroll } from '@vueuse/core';
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { FadeColors } from '@common/enums/fadeColors';
import { ProductsCategory } from '../interfaces';

const props = defineProps({
  selectedCategory: {
    type: Object as PropType<HubsterCategory>,
    required: false,
    default: () => ({}),
  },
  productCategories: {
    type: Array as PropType<ProductsCategory[]>,
    required: true,
  },
  isCartEmpty: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['updateCategory']);

const activeCategory = ref<number>(0);
const scrollingToActive = ref<boolean>(false);
const showAlcoholConfirm = ref<boolean>(false);
const selectedItem = ref<HubsterMenuPublishItem>();
const categoryRefs = ref<(HTMLElement | null)[]>([]);

const route = useRoute();
const menuStore = useMenuStore();
const { loading } = useMenuController();
const cartStoreV2 = useCartStoreV2();
const metadataStore = useMetadataStoreV2();
const { alcohol } = storeToRefs(menuStore);
const { startCustomization } = useCustomize();
const { priceDecimals } = storeToRefs(metadataStore);
const { triggerSelectItem, triggerAddToCart } = useGTMEventsComposableV2();
const { hasBanners } = useRandomBanner();

// Monitor scroll position
const scrollContainerRef = ref<{ contentRef: HTMLElement }>();
const container = computed(() => scrollContainerRef.value?.contentRef || null);
const { y } = useScroll(container);

function addItem(item: HubsterMenuPublishItem) {
  triggerSelectItem(
    {
      id: item.id,
      name: item.name,
      price: item.price.amount,
      quantity: 1,
    },
    props.selectedCategory,
  );

  if (item.modifierGroupIds.length === 0) {
    cartStoreV2.addItemWithoutCustomization(item.id);

    // Trigger GTM events
    triggerAddToCart({
      item: toGTMCartItem(cartStoreV2.items.at(-1)!),
      section: route.name,
      operation: CartEventType.ADD,
    });
    return;
  }

  startCustomization(item.id);
}

function setSelectedProduct(item: HubsterMenuPublishItem) {
  selectedItem.value = item;
}

function updateActiveCategory() {
  const scrollTop = y.value;
  // Buffer to ensure smooth category transitions
  const bannerHeight = hasBanners.value ? 390 : 0;
  const buffer = 200 + bannerHeight;

  categoryRefs.value.forEach((category, index) => {
    if (category) {
      const rect = category.getBoundingClientRect();
      const top = rect.top + scrollTop;
      const bottom = top + rect.height;

      if (scrollTop + buffer >= top && scrollTop + buffer < bottom) {
        if (scrollingToActive.value && index !== activeCategory.value) return;

        activeCategory.value = index;
        scrollingToActive.value = false;
        emit('updateCategory', props.productCategories[index].id);
      }
    }
  });
}

function confirmProductSelection() {
  showAlcoholConfirm.value = false;
  menuStore.setAlcoholValue({ allow: true, checked: true });
  if (selectedItem.value) addItem(selectedItem.value);
}

function denyAlcoholProducts() {
  showAlcoholConfirm.value = false;
  menuStore.setAlcoholValue({ allow: false, checked: true });
  // Don't modify selectedItem to keep its original status
}

function scrollToActiveCategory() {
  // Make scroll
  categoryRefs.value[activeCategory.value]?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
}

function openAlcoholConfirm(item: HubsterMenuPublishItem) {
  setSelectedProduct(item);

  if (alcohol.value.checked) {
    confirmProductSelection();
    return;
  }
  // Show alcohol confirm
  showAlcoholConfirm.value = true;
}

watch(y, () => {
  updateActiveCategory();
});

watch(
  () => props.selectedCategory,
  async (newCategory) => {
    if (!newCategory) return;
    const newIndex = props.productCategories.findIndex((c) => c.id === newCategory.id);
    if (newIndex === activeCategory.value) return;

    scrollingToActive.value = true;
    activeCategory.value = newIndex;
    await nextTick();
    scrollToActiveCategory();
  },
  { immediate: true },
);
</script>

<template>
  <div v-if="!loading" class="h-full">
    <ScrollContainer
      ref="scrollContainerRef"
      height="h-full"
      width="w-full"
      :bottom="true"
      :top="true"
      :color="FadeColors.WHITE"
      class="h-full"
    >
      <div
        v-for="(category, index) in productCategories"
        :key="category.id"
        :ref="el => (categoryRefs[index] = el as HTMLElement)"
        :class="{ active: activeCategory === index }"
        class="category"
      >
        <div class="flex items-center gap-10 px-6">
          <div class="separator" />

          <h3 class="text-4xl leading-4 text-neutral-400">
            {{ category.name }}
          </h3>

          <div class="separator" />
        </div>

        <div
          class="grid content-start grid-cols-3 gap-6 p-6 justify-items-center"
          :key="category.id"
        >
          <CatalogItem
            v-for="product in category.items"
            :key="product.id"
            :product="product"
            :price-decimals="priceDecimals"
            :allow-alcohol="alcohol.allow"
            class="rounded-cards"
            @add-item="addItem(product)"
            @show-alcohol-confirm="openAlcoholConfirm(product)"
          />
        </div>
      </div>
    </ScrollContainer>
  </div>

  <!-- LOADER SKELETONS -->
  <template v-else>
    <div class="w-[calc(1080px-14rem)] h-[1800px] overflow-hidden mb-2">
      <div class="category">
        <div class="flex items-center gap-10 px-6">
          <div class="separator" />

          <div class="w-40 h-8 skeleton" />

          <div class="separator" />
        </div>
        <div
          class="grid content-start h-full grid-cols-3 gap-6 p-6 overflow-auto justify-items-center"
        >
          <div v-for="n in 15" :key="n" class="w-full h-96 skeleton" />
        </div>
      </div>
    </div>
  </template>

  <AlcoholConfirm
    :show-modal="showAlcoholConfirm"
    @cancel="denyAlcoholProducts()"
    @confirm="confirmProductSelection()"
  />
</template>

<style scoped>
.scroll-container {
  @apply overflow-y-auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
}

.category {
  @apply py-8;
}

.separator {
  @apply grow h-0.5 bg-neutral-400/50 my-4;
}
</style>
