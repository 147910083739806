import { RouteName } from '@common/routes/routeName';
import { RouteRecordRaw } from 'vue-router';
import OrderOptionsWrapper from '@/modules/orders/OrderOptionsWrapper.vue';
import OrderTypeView from '@/modules/order-type/views/OrderTypeView.vue';
import OrderCategoriesView from '@/modules/orders/views/OrderCategoriesViewLegacy.vue';
import OrderProductsView from '@/modules/orders/views/OrderProductsView.vue';
import DropdownCustomizeItem from '@/modules/customize/views/DropdownCustomizeItem.vue';
import StepCustomizeItem from '@/modules/customize/views/StepCustomizeItem.vue';
import CartView from '@/modules/cart/views/CartView.vue';
import VerticalStepCustomize from '@/modules/customize/views/VerticalStepCustomize.vue';
import TablePickerView from '@/modules/table/views/TablePickerViewLegacy.vue';
import { tablePageGuard } from '@/modules/table/guards/tablePageGuard';
import CheckoutViewV2 from '@/modules/checkout-migration/views/CheckoutViewV2.vue';

import OrdersWrapper from './OrdersWrapper.vue';

export const orderRoutes: RouteRecordRaw[] = [
  {
    path: '',
    name: RouteName.WRAPPER,
    component: OrdersWrapper,
    redirect: { name: RouteName.ORDER_OPTIONS },
    children: [
      {
        path: 'options',
        name: RouteName.ORDER_OPTIONS,
        component: OrderOptionsWrapper,
        redirect: { name: 'OrderTypeView' },
        children: [
          {
            path: 'type',
            name: RouteName.ORDER_TYPE,
            component: OrderTypeView,
          },
          {
            path: 'table',
            name: RouteName.TABLE,
            component: TablePickerView,
            beforeEnter: tablePageGuard,
          },
          {
            path: 'categories',
            name: RouteName.ORDER_CATEGORIES,
            component: OrderCategoriesView,
          },
        ],
      },
      {
        path: 'products',
        name: RouteName.PRODUCTS,
        component: OrderProductsView,
      },
      {
        path: 'customize',
        name: RouteName.DROPDOWN_CUSTOMIZE,
        component: DropdownCustomizeItem,
      },
      {
        path: 'customize',
        name: RouteName.STEP_CUSTOMIZE,
        component: StepCustomizeItem,
      },
      {
        path: 'customize',
        name: RouteName.VERTICAL_CUSTOMIZE,
        component: VerticalStepCustomize,
      },
      {
        path: 'cart',
        name: RouteName.CART,
        component: CartView,
      },
      {
        path: 'checkout',
        name: RouteName.CHECKOUT,
        component: CheckoutViewV2,
      },
    ],
  },
];
