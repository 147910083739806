/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { CustomizeTempSubtree, GlobalConfig, ItemDescriptor, ModifierFactory } from '@slabcode/hubster-modifiers-utils';

export function customizeItem(
  item: ItemDescriptor,
  modifierFactory: ModifierFactory,
  action: (descriptor: CustomizeTempSubtree) => void,
): void {
  if (item.disabled || !item.available) return;

  if (item.modifiers) {
    const itemClone = item.clone();
    action({
      itemClone,
      modifiers: itemClone.modifiers!,
      saveChanges: () => {
        item.modifiers = itemClone.modifiers;
        item.discardSelectionCandidates = itemClone.discardSelectionCandidates;
      },
    });
    return;
  }

  const itemClone = item.clone();
  const modifiers = itemClone.itemMeta.modifierGroupIds.map((modId) =>
    modifierFactory(GlobalConfig().modifiersMap![modId], itemClone));

  itemClone.modifiers = modifiers;
  action({
    itemClone,
    modifiers,
    saveChanges: () => {
      item.modifiers = modifiers;
      item.discardSelectionCandidates = itemClone.discardSelectionCandidates;
    },
  });
}
