<script lang="ts" setup>
const route = useRoute();
const couponStore = useCouponStore();

defineProps({
  actionLbl: {
    type: String,
    required: true,
  },
  price: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

defineEmits<{
  back: [],
  next: [],
}>();

const isActiveCouponView = computed(() => route.name === RouteName.COUPON);

</script>

<template>
  <CartActions
    :disabled="disabled"
    :action-lbl="actionLbl"
    :show-main-action="!isActiveCouponView"
    @next="$emit('next')"
    @back="$emit('back')"
  >
    <template v-if="!isActiveCouponView" #price>
      <InvoiceDiscount :price="price" />
    </template>
    <template #left-actions>
      <button
        type="button"
        class="footer-back-btn disabled:bg-transparent"
        @click="couponStore.navigateToCoupons()"
        v-if="couponStore.isVisible"
        :disabled="isActiveCouponView"
      >
        <span :class="['text-4xl icon icon-coupon', { 'text-active': isActiveCouponView }]" />
        <span
          :class="[
            'text-xl font-light',
            { 'text-active': isActiveCouponView },
          ]"
        >{{ $t('COUPON.USE_COUPON') }}</span>
      </button>
    </template>
  </CartActions>
</template>

<style scoped>
.text-active {
  @apply text-kiosk-primary;
}
</style>
