export const fr = {
  FROM: 'À partir de',
  SCAN_QR: 'Numériser le QR',
  CLAIM_COUPON: 'Échanger le coupon',
  CUPON: 'Coupon',
  COUPON_QR_DESCRIPTION: 'Approchez le code QR de la lumière verte de notre Kiosque.',
  WRITE_COUPON_CODE: 'Entrez votre code de coupon ici',
  INSERT_TABLE_NUMBER: 'Entrez le numéro du tableau',
  TABLE: 'Table',
  SCAN_QR_OR_TABLE_NUMBER: 'Scannez le QR ou entrez le numéro de l\'appareil',
  NUMBER_TABLE_OR_SCAN_QR: 'Saisissez votre numéro de table ou scannez le QR sur votre appareil',
  NEXT: 'Suivant',
  BACK: 'Retour',
  OPTIONAL: 'Optionnel',
  DONE: 'Prêt',
  WELCOME: 'Bienvenue', // Added WELCOME translation
  SELECT_LANGUAGE: 'Choisissez la langue',
  SELECT_ORDER_TYPE: 'Où voulez-vous manger?',
  SOMETHING_WENT_WRONG: 'Une erreur s\'est produite, veuillez réessayer plus tard',
  INTERNAL_ERROR: 'Erreur interne',
  EAT_HERE: 'Manger sur place',
  RESTART: 'Redémarrer',
  RETRY: 'Réessayer',
  CONTINUE: 'Continuer',
  ACCEPT: 'Accepter',
  EDIT: 'Modifier',
  DELETE: 'Supprimer',
  TAKE_AWAY: 'À emporter',
  PICK_UP_COUNTER: 'Retrait au comptoir',
  CRAVING_SELECTION: 'De quoi avez-vous envie?',
  TOTAL: 'Total',
  TAXES: 'Taxes',
  DISCOUNTS: 'Remises',
  SUBTOTAL: 'Sous-total',
  ADD: 'Ajouter',
  NOT_AVAILABLE: 'Indisponible',
  ADD_CART: 'Ajouter au panier',
  CART: 'Panier',
  SAVE: 'Enregistrer',
  SAVED: 'Économisé',
  CUSTOMIZE: 'Personnaliser',
  QUANTITY: 'Montant',
  PRICE: 'Prix',
  ITEMS: 'Articles',
  SKIP: 'Passer',
  TYPE_HERE: 'Écrivez ici',
  ADD_VOUCHER: 'Ajouter une promotion',
  SCAN_VOUCHER: 'Scannez le code QR code',
  ADD_ANOTHER_VOUCHER: 'Ajouter un autre promotion',
  GO_BACK: 'Retour',
  VOUCHER_REJECTED: 'Promotion refusé',
  VOUCHER_REJECTED_DETAIL: 'Désolé, une erreur est survenue lors de la lecture du QR code. Veuillez réessayer',
  CASH: 'Espèces',
  CARD: 'Carte bancaire',
  PAY_ON_SITE: 'Payer à la caisse',
  PAY: 'Payer',
  MAIN_MENU: 'Menu principal',
  MODIFIERS_BACK_TITLE: 'Voulez-vous revenir au menu?',
  MODIFIERS_BACK_DESCRIPTION: 'Vous perdrez les modifications apportées à ce produit',
  CANCEL: 'Annuler',
  CONTAINS_ALCOHOL: 'Contient de l\'alcool',
  TIMELINE: {
    CHOOSE_PRODUCT: 'Choisir un produit',
    CUSTOMIZATION: 'Personnaliser',
    CONFIRM: 'Confirmer',
  },
  ORDER: {
    BACK_MENU: 'Retour au menu',
    BACK_CART: 'Retour au panier',
    UPGRADE_MODIFIER_QUESTION: 'Voulez-vous augmenter votre combo?',
    ACCEPT_UPGRADE: 'Agrandi pour',
    DENY_UPGRADE: 'Non',
    UPGRADE_MODIFIER_DESC: 'Le combo va s\'agrandir',
    UPGRADE_LABEL: 'Combo agrandi',
    EMPTY_CART: 'Oups ! Votre panier est vide',
    EMPTY_CART_DESCRIPTION: 'Ou choisissez quelque chose de délicieux dans le carrousel pour l’ajouter à votre panier.',
  },
  CANCEL_ORDER: {
    QUESTION: 'Voulez-vous annuler cette commande?',
    ADVICE: 'Les modifications seront perdues et vous retournerez au début',
    CONFIRMATION: 'Oui, annuler',
    CANCEL: 'Retour',
  },
  DELETE_ITEM: {
    QUESTION: 'Voulez-vous supprimer ce produit?',
    ADVICE: 'Vous allez éliminer le produit',
    CONFIRMATION: 'Oui, supprimer',
  },
  INACTIVITY: {
    TITLE: 'Vous êtes toujours là?',
    ADVICE: 'Dans quelques secondes, la commande sera réinitialisée et vous retournerez au début',
    QUESTION: 'Voulez-vous continuer votre commande?',
  },
  MODIFIERS_MODAL: {
    TITLE: 'Personnalisez les ingrédients',
  },
  CHECKOUT: {
    CLIENT_INFO_TITLE: 'Entrez votre nom, nous vous appellerons lorsque votre commande sera prête',
    SUMMARY_TITLE: 'Consultez le récapitulatif de votre achat et effectuez le paiement',
    SUMMARY_PAYMENT_MESSAGE: 'Déplacez ou glissez la carte dans le terminal pour finaliser votre achat',
    GET_CLOSE_CARD: 'Insérez la carte dans le terminal',
    REJECTED_PAY: 'Paiement refusé',
    REJECTED_PAY_AGAIN: 'Paiement refusé à nouveau',
    REJECTED_DESCRIPTION: 'Nous sommes désolés pour ce qui s\'est passé, veuillez <b>passer à la caisse</b> pour passer votre commande',
    CASH_PAY: 'Payer en espèces',
    PROCESSING: 'Traitement de votre commande',
    CANCEL: 'Pour annuler une commande',
    TABLE: 'Choisissez comment vous souhaitez recevoir votre commande',
    YOU_CAN_ALSO: 'Tu peux aussi',
  },
  SUCCESS_ORDER: {
    TITLE: 'Commande créée avec succès',
    DESCRIPTION: 'Veuillez <b>vous rendre à la caisse enregistreuse spéciale du Borne</b> et y payer votre commande',
    ORDER: 'Numéro de commande',
    CARD_DESCRIPTION: 'votre paiement a réussi, <b>profitez de votre commande</b>',
    POS_FAIL: 'Veuillez <b>passer à la caisse</b> avec la facture pour vérifier votre commande',
  },
  SUCCESS_ORDER_CARD: {
    TITLE: 'Paiement effectué avec succès',
    DESCRIPTION: 'Merci pour votre achat, votre commande sera prête dans quelques minutes',
    VOUCHER: 'Promotion utilisé avec succès',
  },
  USER_INFO: {
    NAME: 'Nom',
    NAME_NIT: 'Nom ou raison sociale',
    COMPANY_NAME: 'Raison sociale',
    EMAIL: 'E-mail',
    DOCUMENT_ID: 'Numéro d\'identification / SIRET',
    PHONE: 'Numéro de téléphone',
    PHONE_PLACEHOLDER: '3101234567',
    EMAIL_PLACEHOLDER: "mail{'@'}mail.com",
    NAME_PLACEHOLDER: 'Nom Prénom',
    DOCUMENT_PLACEHOLDER: 'Pas de chiffre de contrôle',
    VALIDATIONS: {
      REQUIRED: 'Le champ "{field}" est obligatoire',
      EMAIL: 'Le format de l\'e-mail n\'est pas valide',
      ALPHA: 'Le champ ne doit contenir que des lettres',
      BILLING_NEEDED: 'Données nécessaires à l\'émission d\'une facture électronique',
      BILLING_ID_NEEDED: 'Données nécessaires pour émettre une facture électronique et cumuler des points',
      BILLING_NAMES_NEEDED: 'Les noms et prénoms, ou la raison sociale sont nécessaires pour émettre une facture électronique',
      MIN_LENGTH: 'Le champ "{field}" doit contenir au moins {length} caractères',
      MAX_LENGTH: 'Le champ "{field}" doit contenir au maximum {length} caractères',
    },
    CLIENT_TYPE: {
      REGULAR: 'Personne physique',
      COMPANY: 'Entité juridique',
    },
  },
  ALCOHOL: {
    TITLE: 'Attention',
    GALLERY_MESSAGE: 'Ce produit contient de l\'alcool.\nVeuillez confirmer que vous avez l\'âge légal avant de procéder à l\'achat',
    MODIFIERS_MESSAGE: 'Certaines options de ce produit contiennent de l\'alcool.\nVeuillez confirmer que vous avez l\'âge légal avant de choisir vos options.',
    CONFIRM: '+18 ans',
    CANCEL: 'Je suis mineur',
  },
  SPACE: 'espace',
  ORDER_HERE: 'Appuyez pour commander!',
  PRODUCTS: {
    OUT_OF_STOCK: 'Épuisé',
  },
  LEGAL: {
    TERMS_AND_CONDITIONS: "J'accepte ,es Conditions d'utilisation",
    PRIVACY_POLICY: "J'accepte la Politique de Confidentialité",
  },
  CURRENCY: {
    MODAL_TITLE: 'Avec quelle devise souhaitez-vous payer ?',
    MODAL_DESCRIPTION: 'Sélectionnez une devise pour continuer',
    PAY: 'Payer',
    BACK: 'Retour',
  },
  TIPS: {
    MODAL_TITLE: 'Souhaitez-vous remercier la personne qui préparera votre repas ?',
    MODAL_DESCRIPTION: 'Laissez un pourboire et montrez votre appréciation',
    ADD_AND_PAY: 'Ajouter et payer',
    ADD_AND_CONTINUE: 'Ajouter et continuer',
    OMIT_AND_PAY: 'Passer et payer',
    OMIT_AND_CONTINUE: 'Passer et continuer',
  },
  STEP: 'Étape',
  SOMETHING_WENT_WRONG_PAGE: {
    TITLE: 'Quelque chose a mal tourné',
    DESCRIPTION: 'Ne vous inquiétez pas, cela peut arriver de temps en temps',
    BACK_HOME: 'Retour au début',
  },

  COUPON: {
    USE_COUPON: 'Utiliser un coupon',
    NOTE_VALIDATION: 'N\'oubliez pas que pour maintenir votre coupon actif, vous devez respecter les conditions établies',
    COUPON_SECTION: 'Section des coupons',
    CHOSE: 'Choisissez comment utiliser votre coupon.',
    REDEEM_MANUALLY: 'Utiliser manuellement',
    WRITE_KEYBOARD: 'Saisissez le code de votre coupon en utilisant le clavier du kiosque',
    REDEEM_QR: 'Avec un code QR',
    SCAN_QR: 'Approchez le code QR du lecteur, dans le coin inférieur gauche du kiosque.',
    VALIDATING: 'Validation du coupon',
    TAKE_SECONDS: 'Cela ne prendra que quelques secondes',
    DO_YOU_WANT_TO_ADD: 'Voulez-vous ajouter autre chose de délicieux à votre panier?',
    CONTINUE_SHOPPING: 'Continuer les achats',
    GO_TO_CART: 'Aller au panier',
    OTHER_COUPON: 'AUTRE COUPON',
    CLOSE: 'Fermer',
    OPTION: 'Option',
    COUPON_NOT_AVAILABLE: 'Ce coupon n’est plus disponible',
    LOOKS_IS_NOT_AVAILABLE: 'Il semble que ce coupon ne soit plus actif. Ne vous inquiétez pas, vous pouvez en essayer un autre ou consulter les nouvelles promotions disponibles',
    COUPON_ALREADY_REDEEMED: 'Votre coupon a déjà été utilisé',
    IF_YOU_WANT_DELETE_FIRST: 'Si vous souhaitez échanger un autre coupon, vous devrez d\'abord supprimer celui que vous venez d\'appliquer.',
    COUPON_DELETED: 'Le coupon a été supprimé et retiré de votre panier.',
    DELETED_SUGGESTION: 'Souhaitez-vous utiliser un autre coupon ou continuer vos achats?',
    COUPON_COULD_NOT_BE_DELETED: 'Le coupon n\'a pas pu être supprimé.',
    NOT_DELETED_SUGGESTION: 'Souhaitez-vous réessayer ou continuer vos achats?',
  },
  CHECK_IN: {
    CHOOSE_LANGUAGE: 'Choisissez la langue',
    FULFILLMENT_TITLE: 'Comment souhaitez-vous votre commande?',
    TABLE_PICKER_TITLE: 'Comment souhaitez-vous recevoir votre commande?',
    PICK_UP_TABLE: 'Service à table',
    PICK_UP_COUNTER: 'Je récupère au comptoir',
    CHOOSE_CATEGORY: 'Sélectionnez une catégorie',
  },
  UPDATE_VERSION: {
    UPDATING: 'Nouvelle version disponible',
    DESCRIPTION: 'Une nouvelle version du système est disponible.',
    UPDATE_NOW: 'Mettre à jour maintenant',
  },
};

export default fr;
