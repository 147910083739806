<script lang="ts" setup>
import { RouteName } from '@common/routes/routeName';
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { ProductsCategory } from '../interfaces';

const route = useRoute();
const router = useRouter();
const reset = useReset();
const cartStore = useCartStoreV2();
const menuStore = useMenuStore();
const modalStore = useModalStore();
const multibrandStore = useMultibrandStore();
const { hasBanners } = useRandomBanner();

const scrollInCatalog = ref(false);
const { getFormatPrice } = useTotal();
const { currentCategory, categories, filteredMenu } = storeToRefs(menuStore);
const { triggerCancelOrder, triggerViewItemList } = useGTMEventsComposableV2();

const productCategories = computed((): ProductsCategory[] => {
  if (!categories.value.length) return [];

  return categories.value.map((c: HubsterCategory) => ({
    ...c,
    items: c.itemIds
      .map((id) => {
        if (!filteredMenu.value) return null;
        return filteredMenu.value.menu.items[id];
      })
      .filter((i): i is HubsterMenuPublishItem => Boolean(i)),
  }));
});

function cancelOrder() {
  triggerCancelOrder({
    items: toGTMCartItems(cartStore.items),
    section: route.name!.toString(),
    isModal: false,
  });

  if (multibrandStore.multibrand) {
    const { cancelUrl } = multibrandStore;
    window.location.href = cancelUrl;
    return;
  }

  reset.flushState();
}

function validateCancelOrder() {
  if (cartStore.items.length) {
    modalStore.showModal = true;
    return;
  }

  cancelOrder();
}

function selectCategory(category: HubsterCategory) {
  scrollInCatalog.value = true;
  menuStore.selectCategory(category.id);
  // Trigger GTM event
  triggerViewItemList(category);
}

function goCart() {
  router.push({ name: RouteName.CART });
}
</script>

<template>
  <section
    class="bg-kiosk-background full-hd"
    :class="hasBanners ? 'layout-container' : 'layout-container-no-banner'"
  >
    <OrderMenu
      :menu-items="productCategories"
      @go-back="validateCancelOrder()"
      @select-category="selectCategory($event)"
    />

    <section class="banner" v-if="hasBanners">
      <UpSellBanner />
    </section>
    <section class="overflow-hidden gallery">
      <OrderGalleryV2
        :selected-category="currentCategory!"
        :product-categories="productCategories"
        :is-cart-empty="cartStore.subtotal === 0"
        @update-category="menuStore.selectCategory($event)"
      />
    </section>
    <Transition>
      <FooterActionsCoupon
        :price="getFormatPrice(cartStore.subtotal)"
        :disabled="cartStore.subtotal === 0"
        :action-lbl="$t('CART')"
        @next="goCart()"
        @back="validateCancelOrder()"
      />
    </Transition>
  </section>

  <ConfirmModal
    :show-modal="modalStore.showModal"
    modal-class="!w-[588px]"
    :title="$t('CANCEL_ORDER.QUESTION')"
    :message="$t('CANCEL_ORDER.ADVICE')"
    :success-button-label="$t('CANCEL_ORDER.CONFIRMATION')"
    :deny-button-label="$t('CANCEL_ORDER.CANCEL')"
    @on-ok="cancelOrder()"
  />
</template>

<style scoped>
.layout-container {
  @apply grid;
  grid-template-areas:
    'men banner banner banner'
    'men gallery gallery gallery'
    'men gallery gallery gallery'
    'men gallery gallery gallery'
    'men gallery gallery gallery'
    'footer footer footer footer';
}

.layout-container-no-banner {
  @apply grid;
  grid-template-rows: auto auto auto auto auto 7rem;
  grid-template-areas:
    'men gallery gallery gallery'
    'men gallery gallery gallery'
    'men gallery gallery gallery'
    'men gallery gallery gallery'
    'men gallery gallery gallery'
    'footer footer footer footer';
}

.gallery {
  grid-area: gallery;
}

.banner {
  grid-area: banner;
  padding-bottom: 2rem;
}

.v-enter-active,
.v-leave-active {
  @apply transition-transform duration-500 ease-linear;
}

.v-enter-to,
.v-leave-from {
  @apply translate-y-0;
}

.v-enter-from,
.v-leave-to {
  @apply translate-y-28;
}
</style>
