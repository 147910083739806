import { ClientTypeDto } from '@slabcode/kiosks-core/dtos/settings/cluster';

export const useClientTypeStore = defineStore('clientType', () => {
  const metadataStoreV2 = useMetadataStoreV2();
  const { clusterSettings } = storeToRefs(metadataStoreV2);

  const initialValue = computed(
    () => clusterSettings.value?.customerInfo[0] ?? null,
  );
  const clientType = ref<ClientTypeDto | null>(initialValue.value);

  const clientTypesArray = computed<ClientTypeDto[]>(
    () => clusterSettings.value?.customerInfo ?? [],
  );
  const showClientType = computed(
    () => clientTypesArray.value.filter((item) => item.enabled).length > 1,
  );

  const currentClientTypeIndex = computed(() =>
    clientTypesArray.value.findIndex(
      (item) => item.label === clientType.value?.label,
    ));

  function reset() {
    clientType.value = initialValue.value;
  }

  return {
    clientType,
    clientTypesArray,
    showClientType,
    reset,
    currentClientTypeIndex,
  };
});
