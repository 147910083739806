<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

const { adBanners, onBannerClick } = useRandomBanner();
</script>

<template>
  <div class="h-[380px] max-w-[784px] mx-auto w-full my-8 rounded-cards z-20">
    <!-- Swiper principal -->
    <Swiper
      :slides-per-view="1"
      :space-between="0"
      :centered-slides="true"
      :modules="[Pagination, Autoplay]"
      :speed="800"
      :loop="true"
      :pagination="{
        clickable: true,
        el: '.custom-pagination', // Nombre de la clase para el contenedor
      }"
      :autoplay="{
        delay: 2000,
        disableOnInteraction: false,
      }"
      class="h-full"
    >
      <SwiperSlide v-for="(banner, i) in adBanners" :key="i">
        <button type="button" @click="onBannerClick(banner)">
          <img
            :src="banner.photoIds[1]"
            :alt="banner.id"
            class="w-full h-[380px] object-fill"
          />
        </button>
      </SwiperSlide>
    </Swiper>

    <!-- Contenedor para los dots -->
    <div class="mt-6 custom-pagination" />
  </div>
</template>

<style scoped>
:deep(.swiper.swiper-initialized) {
  @apply rounded-cards shadow-xl;
}
/* Ajusta la posición de los bullets con la clase usada en el :pagination.el */
.custom-pagination {
  @apply flex justify-center items-center gap-[10px];
}

/* Puedes personalizar las balas, por ejemplo: */
:deep(.swiper-pagination-bullet) {
  @apply bg-kiosk-btnPrimaryBg/80 rounded-full mx-2 !w-4 !h-4;
}

:deep(.swiper-pagination-bullet-active) {
  @apply bg-kiosk-btnPrimaryBg;
}
</style>
