<script lang="ts" setup>
const kioskStatusStore = useKioskStatusStore();
const time = useTimeStore();
const { now } = storeToRefs(time);

const counter = ref(10);
onUnmounted(() => {
  counter.value = 10;
});

onMounted(() => {
  counter.value = 10;
});

watch(now, () => {
  if (counter.value === 0) {
    kioskStatusStore.updateVersion();
    return;
  }

  counter.value -= 1;
});
</script>

<template>
  <KioskBorderlessModal to="#updating-modal" modal-class="!w-[600px]">
    <template #default>
      <div class="flex flex-col items-center gap-10 py-5">
        <h1 class="flex flex-col items-center gap-2 text-center dialog-title">
          {{ $t('UPDATE_VERSION.UPDATING') }}
          <small class="text-3xl font-normal text-center">
            {{ $t('UPDATE_VERSION.DESCRIPTION') }}
          </small>
        </h1>
        <div class="relative flex items-center justify-center w-full my-5">
          <span class="text-9xl icon text-kiosk-primary icon-timer" />
          <span class="circle-counter bg-kiosk-primary"> {{ counter }}s </span>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-center w-full">
        <KioskButton
          class="!w-full"
          text-size="base"
          color="primary"
          @on-click="kioskStatusStore.updateVersion()"
        >
          {{ $t('UPDATE_VERSION.UPDATE_NOW') }}
        </KioskButton>
      </div>
    </template>
  </KioskBorderlessModal>
</template>

<style scoped>
.circle-counter {
  @apply flex items-center justify-center text-5xl font-bold text-white h-[90px] w-[90px] rounded-full shadow-xl absolute mt-4 tracking-wider;
}
</style>
