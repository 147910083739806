export function useDefaultCheckoutValidations() {
  const { minLength$, maxLength$, alpha$, email$ } = useValidators();
  const { t } = useI18n();

  const defaultSchema = {
    name: {
      minLength: minLength$(t('USER_INFO.VALIDATIONS.MIN_LENGTH'), 2),
      isNameValid: alpha$,
    },
    email: {
      email: email$,
      minLength: minLength$(t('USER_INFO.VALIDATIONS.MIN_LENGTH'), 5),
    },
    taxIdentificationNumber: {
      minLength: minLength$(t('USER_INFO.VALIDATIONS.MIN_LENGTH'), 6),
      maxLength: maxLength$(t('USER_INFO.VALIDATIONS.MAX_LENGTH'), 10),
    },
  };

  function getByKey(key: string) {
    if (key in defaultSchema) {
      return defaultSchema[key as keyof typeof defaultSchema];
    }

    return {};
  }

  return {
    defaultSchema,
    getByKey,
  };
}
