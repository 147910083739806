import { ClientTypeDto } from '@slabcode/kiosks-core/dtos/settings/cluster';

import { defineStore } from 'pinia';

import type { Customer } from '../interfaces/customer';

export const useCustomerStore = defineStore('customer', () => {
  const metadataStoreV2 = useMetadataStoreV2();
  const { clusterSettings } = storeToRefs(metadataStoreV2);
  // Estado del cliente y su configuración
  // TODO: Preguntar que pasaría si se envía una propiedad que no existe en el estado del cliente
  const customer = ref<Customer>({
    name: '',
    email: '',
    phone: '',
    taxIdentificationNumber: '',
    clientType: undefined,
  });
  const customerConfig = computed<ClientTypeDto[]>(() => clusterSettings.value?.customerInfo ?? []);

  const hasCustomerConfig = computed(() => customerConfig.value.filter((item) => item.enabled).length > 1);
  const showClientType = computed(() => customerConfig.value && hasCustomerConfig.value);

  const showCustomerView = computed(() => {
    if (typeof customer.value.clientType !== 'number') return false;
    return Object.values(
      customerConfig.value[customer.value.clientType].customerFields,
    ).some((item) => item.enabled === true);
  });

  function clear() {
    customer.value = {
      name: '',
      email: '',
      phone: '',
      taxIdentificationNumber: '',
      clientType: undefined,
    };
  }

  function setup() {
    const clientType = customerConfig.value.findIndex((item) => item.enabled);
    if (clientType !== -1) {
      customer.value.clientType = clientType;
    }
  }

  return {
    customer,
    customerConfig,
    hasCustomerConfig,
    showClientType,
    showCustomerView,
    clear,
    setup,
  };
});
