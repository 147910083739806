/* eslint-disable no-param-reassign */
import { ItemDescriptor, quantifiable } from '@slabcode/hubster-modifiers-utils';
import { getItemGroupQuantity } from '@slabcode/hubster-modifiers-utils/src/utils/get-item-group-quantity.util';

export function addQuantityWithSteal(item: ItemDescriptor, quantity: number) {
  if (item.disabled || !item.available) return;

  const parent = item.parent!;
  const groupCurrentQuantity = getItemGroupQuantity(parent.items);
  const isCurrentlyExceedingMaxSelections = groupCurrentQuantity > parent.dynamicMaximumSelections;
  const isAdding = quantity > 0;

  if (isCurrentlyExceedingMaxSelections && isAdding) return;

  const groupNewQuantity = groupCurrentQuantity + quantity;
  const isDecreasing = quantity < 0;
  const canDecrease = isCurrentlyExceedingMaxSelections && isDecreasing;
  const willBeBetweenSelectionsRange = groupNewQuantity >= 0
    && groupNewQuantity <= parent.dynamicMaximumSelections;

  if (willBeBetweenSelectionsRange || canDecrease) {
    if (groupNewQuantity === parent.dynamicMaximumSelections) {
      parent.items.forEach((it) => {
        it.disableAddButton = it.quantity >= parent.dynamicMaxPerModifierSelectionQuantity;
      });
    }

    const newQuantity = item.quantity + quantity;
    if (newQuantity < 0) return;
    item.quantity = newQuantity;
    item.disableAddButton = groupNewQuantity > parent.dynamicMaximumSelections
      || item.quantity >= parent.dynamicMaxPerModifierSelectionQuantity
      || item.quantity >= parent.dynamicMaximumSelections;
    item.disableSubButton = item.quantity <= 0;

    if (item.quantity === 0) {
      item.modifiers = undefined;
      item.discardSelectionCandidates = [];
    }

    item.modifiers?.forEach((mod) =>
      mod.updateModifierConditionsWithQuantity(item.quantity));

    return;
  }

  const elemToStealQuantity = parent.items
    .filter((sibling) => sibling !== item)
    .find((sibling) => sibling.quantity >= quantity);
  if (
    elemToStealQuantity
    && quantifiable(elemToStealQuantity.actions)
    && quantifiable(item.actions)
  ) {
    if (elemToStealQuantity.modifiers) {
      elemToStealQuantity.discardSelection();
    } else {
      elemToStealQuantity.actions.decrease();
    }
    item.actions.increase();
  }
}
