<script lang="ts" setup>
import { RouteName } from '@common/routes/routeName';

const menuStore = useMenuStore();
const gtmStore = useGtmStore();
const metadataStoreV2 = useMetadataStoreV2();
const standbyBannerStore = useStandbyBannerStore();

async function beforeMount() {
  menuStore.reset();
  gtmStore.endOrder();
}

onBeforeMount(() => beforeMount());

onBeforeRouteLeave((currentRoute) => {
  if (currentRoute.name !== RouteName.STAND_BY_BANNER) {
    standbyBannerStore.stopChecking();
  }
});
</script>

<template>
  <KioskLoading v-if="metadataStoreV2.loading" />
  <div v-else class="menu-container">
    <LegacyLanguagesPicker />
  </div>
</template>

<style scoped>
.menu-container {
  @apply flex gap-12 absolute bottom-56 w-full justify-center flex-wrap px-12;
}
</style>
