<script setup lang="ts">
defineProps({
  time: {
    type: Number,
    required: true,
  },
  showModal: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['close', 'inactive']);

const { t } = i18n.global;
</script>

<template>
  <TeleportAnimationModal :show="showModal" to="#idle-modal">
    <KioskBorderlessModal modal-class="!w-[600px]" :track-inactivity="false">
      <template #default>
        <h2 class="dialog-title">
          {{ t('INACTIVITY.TITLE') }}
        </h2>

        <div class="relative flex items-center justify-center w-full my-10">
          <span class="text-9xl icon text-kiosk-primary icon-timer" />

          <span class="circle-counter bg-kiosk-primary"> {{ time }}s </span>
        </div>
      </template>

      <template #footer>
        <div class="flex justify-center w-full">
          <KioskButton
            class="!w-full"
            color="primary"
            text-size="medium"
            @on-click="emit('close')"
          >
            {{ t('CONTINUE') }}
          </KioskButton>
        </div>
      </template>
    </KioskBorderlessModal>
  </TeleportAnimationModal>
</template>

<style scoped>
.modal-text {
  @apply text-2xl text-neutral-600 leading-8 tracking-tight text-center;
}

.circle-counter {
  @apply flex items-center justify-center text-5xl font-bold text-white h-[90px] w-[90px] rounded-full shadow-xl absolute mt-4 tracking-wider;
}
</style>
