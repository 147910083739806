/* eslint-disable no-param-reassign */
import { ItemDescriptor, ModifierDescriptor } from '@slabcode/hubster-modifiers-utils';

function onlyOneSelection(chosenItem: ItemDescriptor, parent: ModifierDescriptor): void {
  chosenItem.modifiers = undefined;
  chosenItem.discardSelectionCandidates = [];
  parent.items
    .filter((sibling) => sibling !== chosenItem)
    .forEach((sibling) => {
      sibling.quantity = 0;
      sibling.modifiers = undefined;
      sibling.discardSelectionCandidates = [];
    });
}

function multipleSelection(parent: ModifierDescriptor) {
  const selectedItemsLength = parent.items.filter(
    (it) => it.selected,
  ).length;

  parent.items
    .filter((it) => !it.selected)
    .forEach((it) => {
      it.disabled = selectedItemsLength === parent.dynamicMaximumSelections;
    });
}

export function selectItem(
  chosenItem: ItemDescriptor,
  quantityAmountFix: number,
): void {
  if (chosenItem.disabled || !chosenItem.available) return;

  // Item is being fixed
  if (chosenItem.quantity > 1) {
    addQuantityWithSteal(chosenItem, quantityAmountFix);
    return;
  }

  const { parent } = chosenItem;
  if (!parent) return;

  chosenItem.quantity = Number(!chosenItem.quantity);

  if (parent.onlyOneItemAllowed) {
    onlyOneSelection(chosenItem, parent);
  } else {
    multipleSelection(parent);
  }

  uniqueModifierPatcher(chosenItem);
}
