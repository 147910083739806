import { ItemDescriptor,
  SelectActionDescriptor } from '@slabcode/hubster-modifiers-utils';
import { selectItem } from '@lib/actions';

export class Selectable implements SelectActionDescriptor {
  item: ItemDescriptor;

  constructor(item: ItemDescriptor) {
    this.item = item;
  }

  select(): void {
    selectItem(this.item, -1);
  }
}
