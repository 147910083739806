<script setup lang="ts">
import { PropType } from 'vue';
import { useImage, useIntersectionObserver } from '@vueuse/core';
import KioskPrimaryLoading from './KioskPrimaryLoading.vue';

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  imageClass: {
    type: String,
    required: false,
    default: '',
  },
  alt: {
    type: String,
    required: false,
    default: '',
  },
  hideBorder: {
    type: Boolean,
    required: false,
    default: false,
  },
  loaderSize: {
    type: String as PropType<'small' | 'medium' | 'large'>,
    required: false,
    default: 'medium',
  },
});

const kioskImageRef = ref<HTMLDivElement>();

const lazySrc = ref<string>('');
const { src, alt } = toRefs(props);
const { isLoading, execute } = useImage({ src: lazySrc.value, alt: alt.value });

// Lazy load to images
useIntersectionObserver(
  kioskImageRef,
  ([entry]) => {
    if (entry?.isIntersecting && lazySrc.value !== src.value) {
      lazySrc.value = src.value;
      execute(200);
    }
  },
);
</script>

<template>
  <div
    ref="kioskImageRef"
    class="flex items-center justify-center kfc-image"
    :class="imageClass"
  >
    <KioskPrimaryLoading v-if="isLoading" :loader-size="loaderSize" />

    <img
      v-else
      :src="lazySrc"
      v-bind="$attrs"
      rel="preload"
      :alt="alt"
      :class="[
        imageClass,
        hideBorder ? '' : 'border border-neutral-500/50',
      ]"
    >
  </div>
</template>
