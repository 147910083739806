<script lang="ts" setup>
import { LanguageLocales } from '@/modules/language/types';

const languageStore = useLanguageStore();
const kioskInfo = useKioskInfoStore();

const showKfcImages = ref(false);

function selectLanguage(langId: LanguageLocales) {
  languageStore.setLanguage(langId);
}

onMounted(() => {
  /**
   * NOTE: This is temporary solution to show KFC images for KFC-Kiosk
   * while the client approves the new fulfillment design.
   */
  if (kioskInfo.integration === 'KFC-Kiosk') {
    showKfcImages.value = true;
  }
});
</script>

<template>
  <div>
    <!-- Language Section -->
    <LanguagePickerView has-father @select-language="selectLanguage" />

    <!-- divider -->
    <div class="w-56 bg-gray-300 h-[1px] my-14 mx-auto" />

    <!-- Fulfillment Section -->
    <FulfillmentView />
  </div>
</template>

<style scoped>
.language-fulfillment-container {
  @apply flex flex-col items-center;
}

.section {
  @apply w-full mb-8;
}
</style>
