<script setup lang="ts">
const props = defineProps({
  inputRef: {
    type: Object as PropType<HTMLInputElement | null>,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  maxLength: {
    type: Number,
    required: true,
  },
  invalidToContinue: {
    type: Boolean,
    required: false,
    default: false,
  },
  closeButtonHidden: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue', 'close', 'confirm']);

const currentClick = ref();
const { maxLength, modelValue, inputRef } = toRefs(props);
const { keyboardDelete, keyboardAddLetter } = useKeyboardUtils();

const numbers = computed(() => {
  const items = [...Array(10).keys()].map((i) => ({
    value: i.toString(),
    order: i === 0 ? 11 : i,
    disabled: modelValue.value.length >= maxLength.value,
  }));

  return [
    ...items,
    { value: 'delete', order: 10, disabled: false },
    { value: 'check_circle_outline', order: 12, disabled: false },
  ];
});

/**
 * Trigger 'update:modelValue' event to parent component
 * with new value.
 * @param value - Number pressed
 */
function updateValue(value: string) {
  // Set value
  if (modelValue.value.length > maxLength.value) return;
  // Set currentValue
  currentClick.value = value;

  if (modelValue.value.length && inputRef.value) {
    const newValue = keyboardAddLetter(inputRef.value, modelValue.value, value);
    emit('update:modelValue', newValue);
  } else {
    emit('update:modelValue', value);
  }
}

/**
 * Check if 'value' is delete to close dialog / delete last item,
 * check to submit current value or calls 'updateValue' method.
 * @param value - New value
 */
function onPressed(value: string) {
  inputRef.value?.focus();
  // Delete
  if (value === 'delete' && inputRef.value) {
    const newText = keyboardDelete(inputRef.value, modelValue.value);
    emit('update:modelValue', newText);
    return;
  }
  // Confirm
  if (value === 'check_circle_outline') {
    // Set currentValue
    currentClick.value = value;
    // Emit event
    emit('confirm');
    emit('close');
    return;
  }

  // Set value
  updateValue(value);
}

function isNumber(value: number | string) {
  return Number.isFinite(+value);
}
</script>

<template>
  <div
    class="left-0 z-50 flex flex-col justify-center w-full py-5 text-3xl bg-white shadow-custom"
  >
    <div
      class="flex items-center justify-center w-full pt-4 pb-6"
      v-if="closeButtonHidden"
    >
      <button
        type="button"
        @click="$emit('close')"
        data-testid="close-button"
        id="close-button"
      >
        <span class="text-5xl icon icon-arrow-down" />
      </button>
    </div>
    <section class="grid grid-cols-3 gap-4 mx-auto numeric-keyboard">
      <button
        v-for="number in numbers"
        :key="`number-${number.value}`"
        :data-testid="`number-${number.value}`"
        :disabled="
          number.disabled
            || (number.value === 'check_circle_outline' && invalidToContinue)
        "
        :style="{ order: number.order }"
        :class="[
          'keyboard-button',
          {
            primary: number.value === 'check_circle_outline',
            '!bg-gray-200': number.value === 'delete',
            clicked: isNumber(number.value) && currentClick === number.value,
          },
        ]"
        @click="onPressed(number.value)"
        type="button"
      >
        <template v-if="isNumber(number.value)">
          {{ number.value }}
        </template>

        <span v-else :class="`icon icon-${number.value}`" />
      </button>
    </section>
  </div>
</template>

<style scoped>
.shadow-custom {
  box-shadow: -4px -8px 100px #cbcbcb7d;
}
button:disabled {
  @apply bg-neutral-100 opacity-50;
}

button.keyboard-button {
  @apply bg-white border shadow-lg w-40 h-32 text-7xl rounded-md;
}

button.primary {
  @apply bg-kiosk-btnPrimaryBg text-kiosk-btnPrimaryText;
}

button.clicked {
  animation: clicked 0.2s alternate;
}

@keyframes clicked {
  0% {
    @apply bg-white;
  }
  100% {
    @apply bg-neutral-600/10;
  }
}
</style>
