<script setup lang="ts">
import { PropType } from 'vue';
import { ItemDescriptor } from '@slabcode/hubster-modifiers-utils';
import { selectedItems } from '@common/utils/modifierSelectedItems';

const props = defineProps({
  item: {
    type: Object as PropType<ItemDescriptor>,
    required: true,
  },
  currency: {
    type: String,
    required: true,
  },
  decimals: {
    type: Number,
    required: true,
  },
  isChildRow: {
    type: Boolean,
    required: true,
  },
  paddingLeft: {
    type: Number,
    required: false,
    default: 16,
  },
  parentQuantity: {
    type: Number,
    required: false,
    default: 1,
  },
});

const { item, currency, decimals } = toRefs(props);

const currencyValue = (value: number) =>
  formatCurrency(currency.value, value, decimals.value);

const childItems = selectedItems(item.value.modifiers);

const hasChildren = computed(() => !!childItems.length);
const childPadding = computed(() =>
  (hasChildren.value ? props.paddingLeft + 16 : props.paddingLeft));

const totalQuantity = computed(() => item.value.quantity * props.parentQuantity);
</script>

<template>
  <tr class="table-columns text-neutral-400">
    <td
      class="border-l-2 border-dotted text-start"
      :style="{ paddingLeft: `${paddingLeft}px` }"
    >
      {{ item.itemMeta.name }}
    </td>
    <td class="text-center">
      {{ totalQuantity }}
    </td>
    <td class="text-end">
      {{ currencyValue(item.itemMeta.price.amount * parentQuantity) }}
    </td>
  </tr>

  <template v-if="hasChildren">
    <OrderCheckoutRowV2
      v-for="child in childItems"
      :key="child.itemMeta.id"
      :item="child"
      :is-child-row="hasChildren"
      :currency="currency"
      :decimals="decimals"
      :padding-left="childPadding"
      :parent-quantity="parentQuantity"
    />
  </template>
</template>

<style scoped>
.table-columns > td {
  @apply text-2xl leading-7 tracking-[0.5px];
}
</style>
