/* eslint-disable no-unused-vars */
export const RouteName = {
  // WRAPPERS
  ORDERS: 'Orders',
  WRAPPER: 'OrderWrapper',
  STORE: 'Store',
  ORDER_OPTIONS: 'OrderOptions',

  // VIEWS
  STAND_BY_BANNER: 'StandbyBanner',
  MAINTENANCE: 'maintenance',
  NOT_FOUND: 'not-found',
  SOMETHING_WENT_WRONG: 'something-went-wrong',
  WELCOME: 'WelcomeView',
  // Fulfillment legacy
  ORDER_TYPE: 'OrderTypeView',
  // KFC banners fulfillment
  FULFILLMENT: 'FulfillmentViewLegacy',
  // Legacy categories
  ORDER_CATEGORIES: 'OrderCategoriesView',

  // Products gallery
  PRODUCTS: 'OrderProductsView',

  // Customize in steps
  STEP_CUSTOMIZE: 'StepCustomize',

  // Customize in dropdown
  DROPDOWN_CUSTOMIZE: 'DropdownCustomize',

  // Customize in vertical
  VERTICAL_CUSTOMIZE: 'VerticalStepCustomize',

  // Preview of items with up sell banner
  CART: 'CartV2',

  // Checkout
  CHECKOUT: 'CheckoutV2',

  // Table view when is a page and not in checkout
  TABLE: 'Table',

  // Coupon input when can be fill
  COUPON: 'Coupon',

  // When there are an active coupon and show the validations
  COUPON_VALIDATION: 'CouponValidation',
  COUPON_ACTIVE: 'CouponActive',

  // check-in
  CHECK_IN: 'CheckIn',
};
