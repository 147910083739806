<script lang="ts" setup>
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';

const metadataStoreV2 = useMetadataStoreV2();
const kioskImagesStore = useKioskImagesStore();
const orderTypeStore = useOrderTypeStore();

const paymentCashEnabled = computed(() => metadataStoreV2.kioskSettings?.payment.cash);
const paymentCardEnabled = computed(() => metadataStoreV2.kioskSettings?.payment.card);

const emit = defineEmits(['openModal']);

function setOrderType(type: PaymentMethod) {
  orderTypeStore.setCreateOrderType(type);
  emit('openModal');
}
</script>

<template>
  <div class="payment-options">
    <div class="option" v-if="paymentCashEnabled">
      <span class="block my-6 text-4xl text-center">{{ $t('PAY_ON_SITE') }}</span>

      <KioskButton
        icon="cash"
        color="outline"
        @on-click="setOrderType(PaymentMethod.CASH)"
      >
        {{ $t('CASH') }}
      </KioskButton>
    </div>

    <div class="option" v-if="paymentCardEnabled">
      <KioskImage
        v-if="kioskImagesStore.customImages64?.paymentMethods"
        :src="kioskImagesStore.customImages64.paymentMethods"
        alt="Payment image"
        hide-border
        class="w-full mx-auto"
        loader-size="small"
      />

      <KioskButton
        icon="credit-card"
        color="primary"
        class="w-full mx-auto"
        @on-click="setOrderType(PaymentMethod.CARD)"
      >
        {{ $t('CARD') }}
      </KioskButton>
    </div>
  </div>
</template>

<style scoped>
.payment-options {
  @apply flex items-end justify-center w-full px-32 mt-24;
}

.payment-options .option {
  @apply flex flex-col justify-center w-1/2 mx-5;
}
</style>
