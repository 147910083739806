import { CouponResponse,
  CouponResponseInfo,
  InvoiceCoupon } from '../interfaces';
import { CardInfo } from '../interfaces/cardInfo';

export const useInvoiceCouponStore = defineStore('invoiceCoupon', () => {
  const invoiceCoupon = ref<InvoiceCoupon | null>(null);
  const couponViewInfo = ref<CardInfo | null>(null);

  function calculateDiscount(cartSubtotal: number) {
    if (!invoiceCoupon.value) return 0;

    const { value: discountValue, valueType: discountType } = invoiceCoupon.value;
    if (discountType === ValueTypes.Price) {
      // Rule: The maximum discount value is the subtotal, even if the coupon value is higher.
      return discountValue > cartSubtotal ? cartSubtotal : discountValue;
    }

    if (discountType === ValueTypes.Percentage) {
      return cartSubtotal * (discountValue / 100);
    }

    return 0;
  }

  function isCouponInvoice(
    couponInfo: CouponResponseInfo['couponInfo'],
  ): couponInfo is InvoiceCoupon {
    return (couponInfo as InvoiceCoupon)?.value !== undefined;
  }

  function setup(couponResponse: CouponResponse) {
    if (!couponResponse.contents) return;
    if (!isCouponInvoice(couponResponse.contents.couponInfo)) return;
    invoiceCoupon.value = couponResponse.contents.couponInfo;

    couponViewInfo.value = {
      title: couponResponse.message.title,
      description: couponResponse.message.description,
      cardTitle: couponResponse.contents.reservedCoupon.promoCode,
      cardDescription: couponResponse.contents.reservedCoupon.description,
      imageUrl: couponResponse.contents.reservedCoupon.imageUrl,
    };
  }

  function reset() {
    invoiceCoupon.value = null;
    couponViewInfo.value = null;
  }

  return {
    invoiceCoupon,
    couponViewInfo,
    isCouponInvoice,
    calculateDiscount,

    setup,
    reset,
  };
});
