import { RouteName } from '@common/routes/routeName';
import { RouteRecordRaw } from 'vue-router';
import CouponView from './views/CouponView.vue';
import CouponWrapper from './views/CouponWrapper.vue';

export const couponRoutes: RouteRecordRaw[] = [
  {
    path: 'coupons',
    component: CouponWrapper,
    children: [
      {
        path: '',
        name: RouteName.COUPON,
        component: CouponView,
      },
      {
        path: 'validation',
        name: RouteName.COUPON_VALIDATION,
        component: () => import('./views/CouponValidation.vue'),
      },
      {
        path: 'active',
        name: RouteName.COUPON_ACTIVE,
        component: () => import('./views/CouponValidation.vue'),
      },
    ],
  },
];
