<script lang="ts" setup>
defineProps({
  name: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const model = defineModel({
  type: Boolean,
  required: true,
});
</script>

<template>
  <label
    class="flex flex-col items-center gap-2 text-lg text-center"
  >
    <div class="flex gap-2">
      <input
        type="checkbox"
        class="custom-toggle"
        :disabled="disabled"
        v-model="model"
      />
    </div>

    {{ name }}
  </label>
</template>
