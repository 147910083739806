export const useCartSummaryStore = defineStore('cartSummary', () => {
  const cartStore = useCartStoreV2();
  const productCouponStore = useProductCouponStore();
  const invoiceCouponStore = useInvoiceCouponStore();
  const tipsController = useTipsController();
  const orderTypeStore = useOrderTypeStore();
  const menuStore = useMenuStore();

  const invoiceDiscount = computed(() => invoiceCouponStore.calculateDiscount(cartStore.subtotal));

  const calculatedTip = computed(() => parseFloat(tipsController.calculateTip(tipsController.tipSelected, invoiceDiscount.value, cartStore.subtotal)));

  const subtotal = computed(() => cartStore.subtotal + productCouponStore.numericDiscountPrice);

  /**
   * Calculates the taxes based on the fulfillment mode and tax rates.
   * If the menu has a taxes configuration for the fulfillment mode, it calculates the taxes based on the subtotal and the tax rate.
   * If the menu does not have a taxes configuration for the fulfillment mode, it returns the normal items taxes.
   * @returns calculated taxes value.
   */
  const totalTaxes = computed(() => {
    const fulfillment = orderTypeStore.fulfillmentMode;
    const taxRate = menuStore.metadataMenuInfo?.taxes;
    if (!fulfillment || !taxRate) return cartStore.taxes;

    if (taxRate[fulfillment]) {
      const rate = taxRate[fulfillment];
      return subtotal.value * rate;
    }

    return cartStore.taxes;
  });

  const totalDto = computed(() => ({
    // Assuming: The rules never overlap (coupon product config and menu taxes rates)
    subtotal: subtotal.value - totalTaxes.value,
    tax: totalTaxes.value,
    discount: invoiceDiscount.value,
    tip: calculatedTip.value,
    total:
      subtotal.value
      + calculatedTip.value
      - invoiceDiscount.value,
  }));

  const itemsCount = computed(() => cartStore.itemsCount + productCouponStore.itemsCount);

  const totalWithDiscounts = computed(
    () => cartStore.subtotal - invoiceDiscount.value,
  );

  const difference = computed(
    () => cartStore.subtotal - totalWithDiscounts.value,
  );

  return {
    totalDto,
    calculatedTip,
    itemsCount,
    totalWithDiscounts,
    difference,
    invoiceDiscount,
  };
});
