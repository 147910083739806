import { StepConfig } from '../interfaces/setupStep';

export const categoriesConfig: StepConfig = {
  setupShowConfig(metadata) {
    const legacyController = useCheckInController();
    const { isLegacy } = legacyController;
    if (!metadata.hideCategories && isLegacy) {
      return RouteName.ORDER_CATEGORIES;
    }
    if (!metadata.hideCategories && !isLegacy) return StepsViews.CHECK_IN_CATEGORIES;

    return null;
  },
};
