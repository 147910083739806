<script lang="ts" setup>
const route = useRoute();
const metadataStore = useMetadataStoreV2();
const inactiveStoreV2 = useInactiveStoreV2();
const cartUtils = useCartUtils();
const { triggerCancelIdleModal } = useGTMEventsComposableV2();

onBeforeMount(() => {
  // Start countdown
  if (!metadataStore.brandSettings?.inactivity) return;

  inactiveStoreV2.setup({
    displayModalSeconds: metadataStore.brandSettings?.inactivity.screen,
    inactivitySeconds: metadataStore.brandSettings?.inactivity.timer,
  });
});

onBeforeUnmount(() => {
  inactiveStoreV2.pauseChecking();
});

function pressed() {
  inactiveStoreV2.resetInactivity();
}

function cancelInactivityModal() {
  inactiveStoreV2.resetInactivity();
  // Trigger GTM event
  triggerCancelIdleModal(route.name);
}
</script>

<template>
  <div
    id="inactive-wrapper"
    @mousedown="pressed"
    @touchstart="pressed"
    @touchend="pressed"
    @touchcancel="pressed"
  >
    <slot />
    <div id="keyboard" />
  </div>

  <OrderInactivityModal
    :show-modal="inactiveStoreV2.displayModal"
    :time="inactiveStoreV2.modalCounter"
    @inactive="cartUtils.restartCart(true)"
    @close="cancelInactivityModal()"
  />
</template>
