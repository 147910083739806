import { StepConfig } from '../interfaces/setupStep';

export const languageAndFulfillmentConfig: StepConfig = {
  setupShowConfig(metadata) {
    // Check if languages should be shown
    const showLanguages = metadata.kioskSettings && metadata.kioskSettings.languages.enabled.length > 1;

    // Check if fulfillment should be shown
    const showFulfillment = (() => {
      if (!metadata.kioskSettings) {
        return false;
      }

      const { dineIn, pickUp } = metadata.kioskSettings.fulfillment;
      return dineIn.enabled && pickUp.enabled;
    })();

    // If both should be shown, return the combined view route
    if (showLanguages && showFulfillment) {
      return StepsViews.CHECK_IN_LANGUAGE_FULFILLMENT;
    }

    // If only languages should be shown
    if (showLanguages) {
      return StepsViews.CHECK_IN_LANGUAGES;
    }

    // If only fulfillment should be shown
    if (showFulfillment) {
      return StepsViews.CHECK_IN_FULFILLMENT;
    }

    return null;
  },
};
