import { CheckoutSteps } from '../constants/checkout-steps';
import { CheckoutStepsType } from '../types/checkout-steps';

export const useCheckoutStepsStore = defineStore('checkoutSteps', () => {
  const clientTypeStore = useClientTypeStore();
  const customerStore = useCustomerStoreV2();
  const legalPolicies = useLegalPolicies();
  const tableController = useTableController();

  const activeIndex = ref(0);
  const groupCustomer = ref(false);

  function getCustomerSteps() {
    if (groupCustomer.value) {
      return {
        [CheckoutSteps.CLIENT_TYPE]: {
          id: CheckoutSteps.CLIENT_TYPE,
          enabled: false,
        },
        [CheckoutSteps.LEGAL]: {
          id: CheckoutSteps.LEGAL,
          enabled: false,
        },
        [CheckoutSteps.CUSTOMER]: {
          id: CheckoutSteps.CUSTOMER,
          enabled:
            customerStore.showCustomerView
            || clientTypeStore.showClientType
            || legalPolicies.showLegalSection,
        },
      };
    }

    return {
      [CheckoutSteps.CLIENT_TYPE]: {
        id: CheckoutSteps.CLIENT_TYPE,
        enabled: clientTypeStore.showClientType,
      },
      [CheckoutSteps.CUSTOMER]: {
        id: CheckoutSteps.CUSTOMER,
        enabled: customerStore.showCustomerView,
      },
      [CheckoutSteps.LEGAL]: {
        id: CheckoutSteps.LEGAL,
        enabled: legalPolicies.showLegalSection,
      },
    };
  }

  const steps = computed(() => {
    const dictionary = getCustomerSteps();

    return {
      ...dictionary,
      [CheckoutSteps.TABLE]: {
        id: CheckoutSteps.TABLE,
        enabled: tableController.showTableInCheckout && tableController.displayTable,
      },
      [CheckoutSteps.PREVIEW]: {
        id: CheckoutSteps.PREVIEW,
        enabled: true,
      },
    } as CheckoutStepsType;
  });

  const availableSteps = computed(() =>
    Object.values(steps.value).filter((step) => step.enabled));
  const currentStep = computed(() => availableSteps.value[activeIndex.value]);

  function setStep(index: CheckoutSteps) {
    activeIndex.value = Object.values(steps.value).findIndex(
      (step) => step.id === index,
    );
  }

  function nextStep() {
    if (activeIndex.value + 1 >= availableSteps.value.length) return;
    activeIndex.value += 1;
  }

  function getStepIndex(stepName: CheckoutSteps) {
    return availableSteps.value.findIndex(
      (step) => step.id === steps.value[stepName].id,
    );
  }

  function previousStep() {
    if (activeIndex.value - 1 < 0) return;
    activeIndex.value -= 1;
  }

  function reset() {
    activeIndex.value = 0;
  }

  function groupByCustomer() {
    groupCustomer.value = true;
  }

  return {
    steps,
    currentStep,
    nextStep,
    previousStep,
    setStep,
    reset,
    getStepIndex,
    groupByCustomer,
  };
});
