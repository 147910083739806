<script setup lang="ts">
// TODO: Rename this component
const modalStore = useModalStore();

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: false,
    default: '',
  },
  successButtonLabel: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: false,
    default: null,
  },
  /**
   * Define if its an store state or local state
   */
  localModal: {
    type: Boolean,
    required: false,
    default: false,
  },
  denyButtonLabel: {
    type: String,
    required: false,
    default: null,
  },
  showModal: {
    type: Boolean,
    required: true,
  },
  modalClass: {
    type: String,
    required: false,
    default: '!w-[600px]',
  },
});

const emit = defineEmits([
  'onOk',
  /**
   * This emit is used when the modal has an localState instead of store state
   */
  'onClose',
]);

function closeModal() {
  if (!props.localModal) {
    modalStore.closeModal();
    return;
  }
  emit('onClose');
}
</script>
<template>
  <TeleportAnimationModal :show="showModal">
    <KioskBorderlessModal :modal-class="modalClass">
      <template #default>
        <template v-if="icon">
          <KioskImage
            :alt="title"
            :src="icon"
            image-class="w-[284px] h-auto"
            hide-border
          />
        </template>

        <h2 class="my-6 uppercase font-big">
          {{ title }}
        </h2>

        <p v-if="message" class="my-14 modal-text">
          {{ message }}
        </p>
      </template>

      <template #footer>
        <div class="flex gap-5 w-full">
          <KioskButton class="!w-full" text-size="small" @on-click="closeModal()">
            {{ denyButtonLabel ? denyButtonLabel : $t('BACK') }}
          </KioskButton>

          <KioskButton
            class="!w-full"
            color="primary"
            text-size="small"
            @on-click="$emit('onOk')"
          >
            {{ successButtonLabel }}
          </KioskButton>
        </div>
      </template>
    </KioskBorderlessModal>
  </TeleportAnimationModal>
</template>

<style scoped>
.modal-text {
  @apply text-3xl text-neutral-500 leading-8 tracking-tight text-center;
}
</style>
