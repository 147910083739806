import { GTMSelectItem } from '@common/interfaces/gtmSelectItem';

import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';

export const useRandomBanner = () => {
  const menuStore = useMenuStore();
  const { upSellingBanners } = storeToRefs(menuStore);
  const adBanners = computed(() => {
    if (!upSellingBanners.value.length) return null;
    return upSellingBanners.value;
  });
  const hasBanners = computed(() => adBanners.value && adBanners.value.length > 0);

  const cartStoreV2 = useCartStoreV2();
  const route = useRoute();
  const { triggerSelectItem, triggerAddToCart } = useGTMEventsComposableV2();
  function onBannerClick(banner: HubsterMenuPublishItem) {
    const addedItem = cartStoreV2.addItemWithoutCustomization(
      banner.id,
      0,
    );
    const gtmItem: GTMSelectItem = {
      id: banner.id,
      name: banner.name,
      price: banner.price.amount,
      quantity: 1,
    };
    triggerSelectItem(gtmItem, {
      name: GTMEvent.UPSELLING,
      id: GTMEvent.UPSELLING,
    });

    triggerAddToCart({
      item: toGTMCartItem(addedItem),
      category: { name: 'UPSELLING', id: 'UPSELLING' },
      section: route.name,
      operation: CartEventType.ADD,
    });
  }

  return {
    adBanners,
    hasBanners,
    onBannerClick,
  };
};
