/* eslint-disable no-use-before-define */
import { ClientTypeDto,
  CustomerFieldDto,
  CustomerFieldsDto } from '@slabcode/kiosks-core/dtos/settings/cluster';

import { ValidationArgs } from '@vuelidate/core';
import { CustomerForm } from '../interfaces/customer-form';
import { CustomerFormField } from '../interfaces/customer-form-field';

export const useCustomerStoreV2 = defineStore('customerV2', () => {
  const languageStore = useLanguageStore();
  const defaultCheckoutValidations = useDefaultCheckoutValidations();
  const clientTypeStore = useClientTypeStore();
  const { clientType } = storeToRefs(clientTypeStore);

  const { required$ } = useValidators();
  const { t } = useI18n();

  const customer = ref<CustomerForm>({
    name: '',
    email: '',
    phone: '',
    idNumber: '',
  });

  const rules = computed((): ValidationArgs => {
    if (!clientType.value) {
      return {
        name: {},
        email: {},
        phone: {},
        idNumber: {},
      };
    }
    return buildFormRules(clientType.value);
  });

  const showCustomerView = computed(() => {
    if (!clientType.value) return false;
    return Object.values(clientType.value.customerFields).some(
      (item) => item.enabled === true,
    );
  });

  const customerFields = computed<CustomerFormField[]>(() => {
    if (!customer.value || !clientType.value) return [];
    const { customerFields: fields } = clientType.value;

    return Object.keys(customer.value).map((key) => {
      const customerKey = key as keyof CustomerForm;
      const customerField = fields[customerKey];
      return {
        enabled: customerField.enabled,
        key: customerKey,
        required: customerField.isMandatory,
        placeholder: customerField.placeholder[languageStore.kioskLanguage],
        label: t(DEFAULT_LABELS[customerKey]),
        info: clientTypeStore.showClientType ? t(DEFAULT_INFO[customerKey]) : '',
        icon: DEFAULT_ICONS[customerKey],
        type: DEFAULT_TYPES[customerKey],
      };
    });
  });

  const customerDto = computed(() => ({
    name: customer.value.name,
    email: customer.value.email,
    phone: customer.value.phone,
    personalIdentifiers: {
      taxIdentificationNumber: customer.value.idNumber,
    },
  }));

  function reset() {
    customer.value.name = '';
    customer.value.email = '';
    customer.value.phone = '';
    customer.value.idNumber = '';
  }

  function isCustomerField(key: string): key is keyof CustomerFieldsDto {
    if (!clientTypeStore.clientType) return false;
    return key in clientTypeStore.clientType.customerFields;
  }

  function getKeyFormRules(
    key: string,
    customerField: CustomerFieldDto,
  ): Record<string, ValidationArgs> {
    const defaultRule = defaultCheckoutValidations.getByKey(key);

    if (customerField.isMandatory) {
      return {
        ...defaultRule,
        required: required$(
          customerField.placeholder[languageStore.kioskLanguage],
        ),
      };
    }

    return defaultRule;
  }

  function buildFormRules(choseClientType: ClientTypeDto) {
    let formRules: Record<string, ValidationArgs> = {};

    Object.entries(choseClientType.customerFields).forEach(([key, value]) => {
      const customerField = value as CustomerFieldDto;
      if (!customerField.enabled || !isCustomerField(key)) return;
      // Build form rules
      formRules = {
        ...formRules,
        [key]: getKeyFormRules(key, customerField),
      };
    });

    return {
      ...formRules,
    };
  }

  return {
    rules,
    customer,
    showCustomerView,
    customerFields,
    customerDto,

    buildFormRules,
    reset,
    isCustomerField,
  };
});
