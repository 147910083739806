<script lang="ts" setup>
import { CheckoutSteps } from '@/modules/checkout-migration/constants/checkout-steps';
import { ExposeFormFields } from '../interfaces/expose-form-fields';

const router = useRouter();
const clientTypeStore = useClientTypeStore();
const legalPolicies = useLegalPolicies();
const checkoutStepsStore = useCheckoutStepsStore();
const tableController = useTableController();
const { steps, currentStep } = storeToRefs(checkoutStepsStore);
const customerForm = ref<ExposeFormFields>();

const canContinue = computed(() => {
  if (currentStep.value.id === CheckoutSteps.TABLE) {
    return tableController.validTableNumber;
  }

  if (currentStep.value.id === CheckoutSteps.CUSTOMER) {
    return legalPolicies.canContinue && !customerForm.value?.invalid;
  }

  return true;
});

function goBack() {
  router.back();
}

onBeforeMount(() => {
  checkoutStepsStore.groupByCustomer();
});
</script>

<template>
  <div class="checkout-container full-hd">
    <section class="steps">
      <CollapsableContainer
        :model-value="currentStep.id !== CheckoutSteps.CUSTOMER"
        :title="$t('CHECKOUT.CLIENT_INFO_TITLE')"
        :show-collapse="false"
        :position="checkoutStepsStore.getStepIndex(CheckoutSteps.CUSTOMER)"
        @finish-animation="customerForm?.handleCollapseFinishAnimation()"
      >
        <ClientType
          v-if="clientTypeStore.showClientType"
        />
        <CustomerForm
          v-if="steps[CheckoutSteps.CUSTOMER].enabled"
          ref="customerForm"
          :collapse="currentStep.id !== CheckoutSteps.CUSTOMER"
        />
        <LegalPolicies v-if="legalPolicies.showLegalSection" />
      </CollapsableContainer>

      <TableNumber
        v-if="steps[CheckoutSteps.TABLE].enabled"
        :collapse="currentStep.id !== CheckoutSteps.TABLE"
        @next="checkoutStepsStore.nextStep()"
      />
      <ConfirmPayment
        v-if="steps[CheckoutSteps.PREVIEW].enabled"
        :collapse="currentStep.id !== CheckoutSteps.PREVIEW"
      />
    </section>

    <CartActions
      :disabled="!canContinue"
      :action-lbl="$t('NEXT')"
      :show-cart-counter="false"
      @next="checkoutStepsStore.nextStep()"
      @back="goBack()"
    >
      <template #right-actions>
        <KioskButton
          @on-click="checkoutStepsStore.previousStep()"
          :disabled="false"
          class="flex gap-5 !text-3xl !py-3.5 !min-w-[259px]"
          text-size="medium"
        >
          {{ $t("BACK") }}
        </KioskButton>
      </template>
    </CartActions>
  </div>

  <PaymentModal />
</template>

<style scoped>
.checkout-container {
  @apply flex flex-col bg-white;
}

.steps {
  @apply flex flex-col flex-grow gap-4 overflow-y-auto;
}
</style>
