import { StepConfig } from '../interfaces/setupStep';

export const fulfillmentConfig: StepConfig = {

  setupShowConfig: (metadata) => {
    if (!metadata.kioskSettings) {
      return null;
    }
    const legacyController = useCheckInController();
    const { isLegacy } = legacyController;

    const { dineIn, pickUp } = metadata.kioskSettings.fulfillment;
    const carouselImage = metadata.customImages?.carousel;

    if (dineIn.enabled && pickUp.enabled && isLegacy) {
      return carouselImage ? RouteName.ORDER_TYPE : RouteName.FULFILLMENT;
    }
    if (dineIn.enabled && pickUp.enabled && !isLegacy) return StepsViews.CHECK_IN_FULFILLMENT;

    return null;
  },

};
