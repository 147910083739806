<script setup lang="ts">
import { ModifierDescriptor } from '@slabcode/hubster-modifiers-utils';
import { PropType } from 'vue';

defineProps({
  modifier: {
    type: Object as PropType<ModifierDescriptor>,
    required: true,
  },
  count: {
    type: Number,
    required: true,
  },
});

const showAnimation = defineModel({ default: false });
</script>

<template>
  <div class="step-header text-[34px] uppercase flex items-center px-12 pb-4 pt-12">
    <h4 class="flex-none max-w-md leading-10 name">
      {{ modifier.modifierMeta.name }}
    </h4>

    <div class="h-[2px] mx-8 grow bg-neutral-300" />

    <OrderModifierCounterV2
      v-model="showAnimation"
      :min-selections="modifier.dynamicMinimumSelections"
      :current-selections="count"
      :max-selections="modifier.dynamicMaximumSelections"
    />
  </div>
</template>
