<script setup lang="ts">
const inactiveStoreV2 = useInactiveStoreV2();
const { resetInactivity } = inactiveStoreV2;

// Props for the modal wrapper
defineProps({
  // Whether to enable inactivity tracking for this modal
  trackInactivity: {
    type: Boolean,
    required: false,
    default: true,
  },
});

// Reset inactivity timer on user interaction
function handleInteraction() {
  resetInactivity();
}
</script>

<template>
  <div class="contents">
    <div
      v-if="trackInactivity"
      @mousedown="handleInteraction"
      @touchstart="handleInteraction"
      @touchend="handleInteraction"
      @touchcancel="handleInteraction"
      class="modal-interaction-tracker"
    >
      <slot />
    </div>

    <template v-else>
      <slot />
    </template>
  </div>
</template>

<style scoped>
.modal-interaction-tracker {
  /* This ensures the div doesn't affect layout but still captures events */
  @apply contents;
}
</style>
