<script lang="ts" setup>
import { FulfillmentMode } from '@common/enums/FulfillmentMode';

const fulfillmentController = useFulfillmentController();
const orderTypeStore = useOrderTypeStore();
const metadataStoreV2 = useMetadataStoreV2();
const kioskInfo = useKioskInfoStore();
const showKfcImages = ref(false);
const legacyController = useCheckInController();
const { isHorizontal } = storeToRefs(legacyController);

onMounted(() => {
  /**
   * NOTE: This is temporary solution to show KFC images for KFC-Kiosk
   * while the client approves the new fulfillment design.
   */
  if (kioskInfo.integration === 'KFC-Kiosk') {
    showKfcImages.value = true;
  }
});

function fulfillmentButtonClick(option: FulfillmentMode) {
  fulfillmentController.redirectFromFulfillment(option);
}

const { brandSettings } = storeToRefs(metadataStoreV2);
const { t } = useI18n();
</script>

<template>
  <div>
    <p class="font-medium text-[30px] uppercase mb-8">
      {{ t('CHECK_IN.FULFILLMENT_TITLE') }}
    </p>
    <div
      :class="[
        'flex gap-8 justify-center w-full flex-col',
        { '!flex-row': isHorizontal },
      ]"
      id="wrapper-buttons"
    >
      <button
        v-for="(option, index) in orderTypeStore.orderOptions"
        type="button"
        :class="[
          'fulfillment-button',
          { 'kfc-button': showKfcImages, '!w-full': !isHorizontal },
        ]"
        :key="index"
        :icon="option.icon"
        @click="fulfillmentButtonClick(option.value)"
      >
        <template v-if="brandSettings?.buttons.showIcons">
          <span
            v-if="!showKfcImages"
            :class="`icon icon-${option.icon} text-[68px] flex items-center justify-center h-[68px] text-gray-700`"
            id="icon"
          />
          <img
            v-else
            :src="option.alternativeKfcImg"
            :alt="$t(option.label)"
            id="img-as-icon"
          />
        </template>
        <span
          class="text-gray-950 font-normal text-[30px] h-[60px] flex justify-center items-center"
        >{{ t(option.label) }}</span>
      </button>
    </div>
  </div>
</template>

<style scoped>
.fulfillment-button {
  @apply flex items-center flex-col justify-center h-[204px] w-[220px] !py-5 px-[40px] leading-7 border-2 rounded-cards cursor-pointer;
  @apply bg-[linear-gradient(129deg,#F8F8F8_2.02%,#F0F0F0_46.38%,#F8F8F8_94.44%)];
}
.kfc-button {
  @apply !w-full border-kiosk-primary;
  background: linear-gradient(
    129deg,
    var(--Grey-50, #f8f8f8) 2.02%,
    var(--Grey-100, #f0f0f0) 46.38%,
    var(--Grey-50, #f8f8f8) 94.44%
  );
}

#wrapper-buttons button:nth-child(2) span:nth-of-type(1) {
  font-size: 50px;
}

.fulfillment-button.kfc-button span:nth-of-type(1) {
  font-size: 30px !important;
}
</style>
