<script setup lang="ts">
const { isLegacy } = useCheckInController();
const availableNewStepsController = useAvailableNewStepsController(); // modern config
// Initialize order
const { initializeOrder } = useOrderInitialization();

onBeforeMount(() => {
  if (isLegacy || availableNewStepsController.stepsInfo.length === 0) {
    initializeOrder();
  }
});
</script>

<template>
  <InactiveWrapper>
    <router-view />
  </InactiveWrapper>
</template>
