<script lang="ts" setup>

</script>

<template>
  <div class="coupon-span">
    {{ $t('CUPON') }} <span class="text-3xl icon icon-coupon" />
  </div>
</template>

<style scoped>
.coupon-span {
  @apply flex items-center gap-2 px-3 py-1 text-2xl uppercase rounded bg-kiosk-primary w-min font-semibold text-kiosk-btnPrimaryText;
}
</style>
