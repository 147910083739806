export const useTableController = defineStore('table', () => {
  const metadataStoreV2 = useMetadataStoreV2();
  const orderTypeStore = useOrderTypeStore();

  const { fulfillmentMode } = storeToRefs(orderTypeStore);
  const { kioskSettings, brandSettings } = storeToRefs(metadataStoreV2);

  const tableNumber = ref('');

  const showTablePage = computed(() => brandSettings.value?.layout.tableNumber === 'fulfillmentView');
  const showTableInCheckout = computed(() => brandSettings.value?.layout.tableNumber === 'checkoutView');
  const validTableNumber = computed(() => tableNumber.value.trim().length > 0);

  /**
   * Determines whether to show the table request based on the current fulfillment mode.
   * @returns {boolean} Returns true if the table form is required and it has to be shown
   */
  const displayTable = computed(() => {
    if (fulfillmentMode.value === FulfillmentMode.DINE_IN) {
      return kioskSettings.value?.fulfillment.dineIn.settings.table ?? false;
    }

    if (fulfillmentMode.value === FulfillmentMode.PICKUP) {
      return kioskSettings.value?.fulfillment.pickUp.settings.table ?? false;
    }

    return false;
  });

  /**
   * Determines whether to allow qr scan based on the current fulfillment mode.
   * @returns {boolean} Returns true if the table form is required and it has to be shown
   */
  const allowQrScanTableNumber = computed(() => {
    if (fulfillmentMode.value === FulfillmentMode.DINE_IN) {
      return kioskSettings.value?.fulfillment.dineIn.settings.qrScanner ?? false;
    }

    if (fulfillmentMode.value === FulfillmentMode.PICKUP) {
      return kioskSettings.value?.fulfillment.pickUp.settings.qrScanner ?? false;
    }

    return false;
  });

  /**
   * Determines whether to show pickup at counter button based on the current fulfillment mode.
   * @returns {boolean} Returns true if the table form is required and it has to be shown
   */
  const displayPickupAtCounter = computed(() => {
    if (fulfillmentMode.value === FulfillmentMode.DINE_IN) {
      return kioskSettings.value?.fulfillment.dineIn.settings.pickUpAtCounter ?? false;
    }

    if (fulfillmentMode.value === FulfillmentMode.PICKUP) {
      return kioskSettings.value?.fulfillment.pickUp.settings.pickUpAtCounter ?? false;
    }

    return false;
  });

  function pickUpAtCounter({ next }: { next: () => void }) {
    tableNumber.value = '';
    next();
  }

  function clear() {
    tableNumber.value = '';
  }

  return {
    // STATE
    tableNumber,

    // METHODS
    pickUpAtCounter,
    clear,

    // GETTERS
    showTableInCheckout,
    showTablePage,
    displayTable,
    displayPickupAtCounter,
    allowQrScanTableNumber,
    validTableNumber,
  };
});
