import { ComponentType } from '@common/types/componentType';
import { StepConfig } from '@/modules/steps/interfaces/setupStep';
import LanguageAndFulfillmentView from '@/modules/checkIn/views/LanguageAndFulfillmentView.vue';
import LanguagePickerView from '@/modules/language/views/LanguagePickerView.vue';
import FulfillmentView from '@/modules/order-type/views/FulfillmentView.vue';
import TablePickerView from '@/modules/table/views/TablePickerView.vue';
import TableNumberView from '@/modules/table/views/TableNumberView.vue';
import CategoriesView from '@/modules/orders/views/OrderCategoriesView.vue';
import ProductsView from '@/modules/orders/views/OrderProductsView.vue';

import { DEFAULT_STEP_CONFIG } from '../constants/defaultStepsConfig';
import { StepsViews } from '../enums/stepsView';
import { StepsConfigType } from '../types/stepsConfigType';

export const useAvailableNewStepsController = defineStore('availableCheckInSteps', () => {
  const metadataStoreV2 = useMetadataStoreV2();
  const tableController = useTableController();
  const kioskImagesStore = useKioskImagesStore();
  const router = useRouter();

  const componentMap = shallowRef<Record<string, ComponentType>>({
    [StepsViews.CHECK_IN_LANGUAGE_FULFILLMENT]: LanguageAndFulfillmentView,
    [StepsViews.CHECK_IN_LANGUAGES]: LanguagePickerView,
    [StepsViews.CHECK_IN_FULFILLMENT]: FulfillmentView,
    [StepsViews.CHECK_IN_TABLE]: TablePickerView,
    [StepsViews.CHECK_IN_TABLE_NUMBER]: TableNumberView,
    [StepsViews.CHECK_IN_CATEGORIES]: CategoriesView,
    [StepsViews.CHECK_IN_PRODUCTS]: ProductsView,
  });

  // State with component mapping
  const stepsConfig = ref<StepsConfigType>({
    [StepsViews.CHECK_IN_LANGUAGE_FULFILLMENT]: {
      component: StepsViews.CHECK_IN_LANGUAGE_FULFILLMENT,
      enabled: false,
      ...DEFAULT_STEP_CONFIG,
    },
    [StepsViews.CHECK_IN_LANGUAGES]: {
      component: StepsViews.CHECK_IN_LANGUAGES,
      enabled: false,
      ...DEFAULT_STEP_CONFIG,
    },
    [StepsViews.CHECK_IN_FULFILLMENT]: {
      component: StepsViews.CHECK_IN_FULFILLMENT,
      enabled: false,
      ...DEFAULT_STEP_CONFIG,
    },
    [StepsViews.CHECK_IN_TABLE]: {
      component: StepsViews.CHECK_IN_TABLE,
      enabled: false,
      ...DEFAULT_STEP_CONFIG,
    },
    [StepsViews.CHECK_IN_TABLE_NUMBER]: {
      component: StepsViews.CHECK_IN_TABLE_NUMBER,
      enabled: false,
      conditional: true,
      showLogo: false,
      maxWidth: DEFAULT_STEP_CONFIG.maxWidth,
    },
    [StepsViews.CHECK_IN_CATEGORIES]: {
      component: StepsViews.CHECK_IN_CATEGORIES,
      enabled: false,
      showLogo: false,
      maxWidth: '772',
    },
    [StepsViews.CHECK_IN_PRODUCTS]: {
      component: StepsViews.CHECK_IN_PRODUCTS,
      enabled: true,
      showLogo: false,
      maxWidth: '772',
    },
  });

  const stepsInfo = ref<Array<{ name: string; enabled: boolean; component: string, showLogo: boolean, maxWidth: string }>>([]);
  const steps = ref<Array<keyof StepsConfigType>>([]);
  const currentStepIndex = ref<number>(0);
  const currentComponent = ref<string>('');

  const isFirstElement = computed(() => currentStepIndex.value === 0);

  const shouldShowLogo = computed(() => {
    const currentStep = steps.value[currentStepIndex.value];

    if (!kioskImagesStore.customImages64?.brandImage) {
      return false;
    }
    if (!currentStep) {
      return false;
    }
    return stepsConfig.value[currentStep]?.showLogo === true;
  });

  // Update steps info
  function updateStepsInfo() {
    stepsInfo.value = steps.value.map((step) => ({
      name: step as string,
      enabled: stepsConfig.value[step]?.enabled ?? false,
      component: stepsConfig.value[step]?.component ?? '',
      showLogo: stepsConfig.value[step]?.showLogo ?? false,
      maxWidth: stepsConfig.value[step]?.maxWidth ?? '616',
    })).filter((step) => step.name !== StepsViews.CHECK_IN_PRODUCTS);
  }

  // Update current component
  function updateCurrentComponent() {
    const currentStep = steps.value[currentStepIndex.value];
    currentComponent.value = currentStep ? stepsConfig.value[currentStep]?.component : '';
  }

  // Add step next to the current step
  function addNextStep(step: keyof StepsConfigType, index: number) {
    // not add if exists
    if (!steps.value.includes(step)) {
      steps.value.splice(index, 0, step);
      stepsConfig.value[step].enabled = true;
      updateCurrentComponent();
      updateStepsInfo();
    }
  }

  function removeStep(step: keyof StepsConfigType) {
    steps.value = steps.value.filter((s) => s !== step);
    stepsConfig.value[step].enabled = false;
    updateCurrentComponent();
    updateStepsInfo();
  }

  // Next step
  function nextStep() {
    currentStepIndex.value += 1;
    if (currentStepIndex.value < steps.value.length - 1) {
      updateCurrentComponent();
      updateStepsInfo();
    } else {
      router.push({ name: RouteName.PRODUCTS });
    }
  }

  // Previous step
  function previousStep() {
    if (currentStepIndex.value > 0) {
      currentStepIndex.value -= 1;
      updateCurrentComponent();
      updateStepsInfo();
    }
  }

  function updateSteps() {
    removeStep(StepsViews.CHECK_IN_TABLE_NUMBER);
    removeStep(StepsViews.CHECK_IN_TABLE);
    if (steps.value.length === 0) return;
    if (tableController.showTableInCheckout) return;
    if (tableController.displayTable && tableController.displayPickupAtCounter) {
      addNextStep(StepsViews.CHECK_IN_TABLE, 1);
    } else if ((tableController.displayTable && !tableController.displayPickupAtCounter) || (tableController.displayTable)) {
      addNextStep(StepsViews.CHECK_IN_TABLE_NUMBER, 1);
    }
    updateStepsInfo();
    updateCurrentComponent();
  }

  function setup() {
    steps.value = [];

    const configViews: StepConfig[] = [
      languageAndFulfillmentConfig,
      categoriesConfig,
    ];

    configViews.forEach((configView) => {
      const enabledRoute = configView.setupShowConfig(metadataStoreV2);

      if (!enabledRoute) {
        return;
      }

      stepsConfig.value[enabledRoute].enabled = true;

      steps.value.push(enabledRoute);
    });

    steps.value.push(StepsViews.CHECK_IN_PRODUCTS);
    stepsConfig.value[StepsViews.CHECK_IN_PRODUCTS].enabled = true;

    updateStepsInfo();
    currentStepIndex.value = 0;
    updateCurrentComponent();
    updateSteps();
  }

  function reset() {
    currentStepIndex.value = 0;
    currentComponent.value = '';
  }

  watch(
    () => [tableController.displayTable, tableController.displayPickupAtCounter],
    () => {
      updateSteps();
    },
  );

  return {
    steps,
    stepsInfo,
    stepsConfig,
    currentStepIndex,
    currentComponent,
    componentMap,
    isFirstElement,
    shouldShowLogo,
    setup,
    nextStep,
    previousStep,
    addNextStep,
    removeStep,
    reset,
  };
});
