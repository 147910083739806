/* eslint-disable no-use-before-define */
import { RouteName } from '@common/routes/routeName';

import { watchPausable } from '@vueuse/core';

import { useTimeStore } from './time';

export const useMaintenanceStore = defineStore('maintenance', () => {
  const router = useRouter();
  const route = useRoute();
  const timeStore = useTimeStore();
  const kioskStatusStore = useKioskStatusStore();

  const openStore = ref(true);
  const banner = ref<string | null>(null);
  const validateAgainCounter = ref(0);

  const { now } = storeToRefs(timeStore);

  const { pause, resume } = watchPausable(now, () => {
    validateAgainCounter.value += 1;
    // Validate each 3 minutes if is open
    if (validateAgainCounter.value === 180) {
      validateAgainCounter.value = 0;
      kioskStatusStore.getKioskStatus().then(() => {
        setup();
      });
    }
  });

  function startChecking() {
    validateAgainCounter.value = 0;
    resume();
  }

  function stopChecking() {
    validateAgainCounter.value = 0;
    pause();
  }

  function checkStatus(isOpen: boolean) {
    if (route.name === RouteName.MAINTENANCE && isOpen) {
      stopChecking();
      router.push({ name: RouteName.WELCOME });
    }

    if (!isOpen) {
      router.push({ name: RouteName.MAINTENANCE });
      startChecking();
    }
  }

  function setup() {
    openStore.value = kioskStatusStore.status;
    if (!openStore.value) {
      banner.value = kioskStatusStore.pausedBanner;
    }
    checkStatus(openStore.value);
  }

  onMounted(() => {
    stopChecking();
  });

  return { setup, banner, openStore, validateAgainCounter };
});
