<script lang="ts" setup>
const { t } = useI18n();

const availableNewStepsController = useAvailableNewStepsController();
const metadataStoreV2 = useMetadataStoreV2();
const { brandSettings } = storeToRefs(metadataStoreV2);
const tableController = useTableController();
const legacyController = useCheckInController();
const { isHorizontal } = storeToRefs(legacyController);

const options = [
  {
    key: 'insert-table-number',
    label: 'CHECK_IN.PICK_UP_TABLE',
    icon: 'icon-table-restaurant',
    onClick: () => {
      // Add the table number step and navigate to it
      availableNewStepsController.addNextStep(StepsViews.CHECK_IN_TABLE_NUMBER, 2);
      availableNewStepsController.nextStep();
    },
  },
  {
    key: 'icon-pick-up-counter',
    label: 'CHECK_IN.PICK_UP_COUNTER',
    icon: 'icon-store-mall-directory',
    onClick: () => {
      tableController.pickUpAtCounter({
        next: () => {
          availableNewStepsController.nextStep();
        },
      });
    },
  },
];
const kioskInfo = useKioskInfoStore();
const showKfcImages = ref(false);

onMounted(() => {
  /**
   * NOTE: This is temporary solution to show KFC images for KFC-Kiosk
   * while the client approves the new fulfillment design.
   */
  if (kioskInfo.integration === 'KFC-Kiosk') {
    showKfcImages.value = true;
  }
});

onBeforeMount(() => {
  availableNewStepsController.removeStep(StepsViews.CHECK_IN_TABLE_NUMBER);
});
</script>

<template>
  <div>
    <p class="text-black font-medium uppercase text-[38px] mb-8">
      {{ $t('CHECK_IN.TABLE_PICKER_TITLE') }}
    </p>

    <div
      :class="[
        'flex gap-8 justify-center w-full flex-col',
        { '!flex-row': isHorizontal },
      ]"
      id="wrapper-buttons"
    >
      <button
        v-for="(option, index) in options"
        type="button"
        :class="[
          'fulfillment-button',
          { 'kfc-button': showKfcImages, '!w-full': !isHorizontal },
        ]"
        :key="index"
        @click="option.onClick()"
      >
        <template v-if="brandSettings?.buttons.showIcons">
          <span :class="`icon ${option.icon} text-[68px] text-gray-700`" />
        </template>
        <span
          class="text-gray-950 font-normal leading-8 text-[30px] h-[60px] flex justify-center items-center"
        >{{ t(option.label) }}</span>
      </button>
    </div>
  </div>
</template>

<style scoped>
.fulfillment-button {
  @apply flex items-center flex-col justify-center h-[204px] w-[220px] py-[30px] !p-5 leading-7 border-2 rounded-cards cursor-pointer;
  @apply bg-[linear-gradient(129deg,#F8F8F8_2.02%,#F0F0F0_46.38%,#F8F8F8_94.44%)];
}
</style>
