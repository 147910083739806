<script lang="ts" setup>
const props = defineProps({
  loaderSize: {
    type: String as PropType<'small' | 'medium' | 'large'>,
    required: false,
    default: 'medium',
  },
});

const loaderOptions = {
  small: {
    size: 'w-[38px] h-[38px]',
    transform: 'translate(1 1)',
    strokeWidth: 'stroke-[2px]',
    circlePos: 18,
    path: 'M36 18c0-9.94-8.06-18-18-18',
  },
  medium: {
    size: 'w-[76px] h-[76px]',
    transform: 'translate(2 2)',
    strokeWidth: 'stroke-[4px]',
    circlePos: 36,
    path: 'M72 36c0-18.188-16.12-36-36-36',
  },
  large: {
    size: 'w-[114px] h-[114px]',
    transform: 'translate(3 3)',
    strokeWidth: 'stroke-[6px]',
    circlePos: 36,
    path: 'M108 54c0-27.282-24.18-54-54-54',
  },
};

const loaderStyles = computed(() => loaderOptions[props.loaderSize]);

</script>

<template>
  <svg
    class="animate-spin stroke-kiosk-primary"
    :class="loaderStyles.size"
  >
    <g fill="none" fill-rule="evenodd">
      <g
        :class="loaderStyles.strokeWidth"
        :transform="loaderStyles.transform"
      >
        <circle
          stroke-opacity="0.5"
          :cx="loaderStyles.circlePos"
          :cy="loaderStyles.circlePos"
          :r="loaderStyles.circlePos"
        />
        <path :d="loaderStyles.path" />
      </g>
    </g>
  </svg>
</template>
