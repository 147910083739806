<script lang="ts" setup>
const inactiveStoreV2 = useInactiveStoreV2();

onBeforeMount(() => {
  inactiveStoreV2.pauseChecking();
});
</script>

<template>
  <InactiveWrapper>
    <RouterView />
  </InactiveWrapper>
</template>
