import { CustomizeActionDescriptor,
  CustomizeTempSubtree,
  ItemDescriptor,
  ModifierFactory,
  SelectActionDescriptor } from '@slabcode/hubster-modifiers-utils';
import { selectItem, customizeItem } from '@lib/actions';

export class CustomizableSelectable
implements SelectActionDescriptor, CustomizeActionDescriptor {
  item: ItemDescriptor;

  modifierFactory: ModifierFactory;

  constructor(item: ItemDescriptor, modifierFactory: ModifierFactory) {
    this.item = item;
    this.modifierFactory = modifierFactory;
  }

  select(): void {
    selectItem(this.item, -1);
  }

  // eslint-disable-next-line no-unused-vars
  customize(action: (descriptor: CustomizeTempSubtree) => void): void {
    customizeItem(this.item, this.modifierFactory, action);
  }
}
