<script lang="ts" setup>
const couponName = defineModel({
  type: String,
  required: true,
});
</script>

<template>
  <CouponFlipAnimation tw-class="w-[600px] h-[600px]" :rotate="Boolean(couponName)">
    <template #front-side>
      <figure class="relative">
        <CouponSvg tw-class="transition-colors duration-300 rotate-z-10 fill-white" line-class="stroke-[#b7b2b2]" />
        <figcaption>
          <h1 class="text-3xl font-bold uppercase">
            {{ $t('COUPON.COUPON_SECTION') }}
          </h1>
          <small class="text-2xl font-light text-gray-400">{{ $t('COUPON.CHOSE') }}</small>
        </figcaption>
      </figure>
    </template>
    <template #back-side>
      <figure class="relative h-full">
        <CouponSvg tw-class="transition-colors duration-300 rotate-z-10 fill-kiosk-primary" line-class="stroke-white" />
        <figcaption>
          <span class="w-full text-4xl uppercase font-bold border-b break-words !text-white py-1">
            {{ couponName }}
          </span>
        </figcaption>
      </figure>
    </template>
  </CouponFlipAnimation>
</template>

<style scoped>
figure figcaption {
  @apply w-[320px] z-20 absolute flex flex-col h-[calc(600px-134px)] justify-center top-0 left-[110px];
}
</style>
