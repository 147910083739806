<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { type Swiper as ISwiper } from 'swiper/types';
import { EffectFade } from 'swiper/modules';
import { RouteName } from '@common/routes/routeName';

const route = useRoute();

// Banners
const kioskImagesStore = useKioskImagesStore();
const kioskStatusStore = useKioskStatusStore();
const { banners64 } = storeToRefs(kioskImagesStore);
// Steps
const availableNewStepsController = useAvailableNewStepsController();

const {
  stepsInfo,
  currentStepIndex,
  componentMap,
  steps,
  isFirstElement,
  shouldShowLogo,
} = storeToRefs(availableNewStepsController);

// Swiper reference
const swiperRef = ref<ISwiper | null>(null);

// Create an array of components to render in swiper
const componentsToRender = computed(() => steps.value.map((step) => ({
  component: componentMap.value[step],
  key: step,
})).filter((item) => item.component));

const carouselImage = computed(() => kioskImagesStore.customImages64?.carousel ?? false);

// Initialize order
const { initializeOrder } = useOrderInitialization();

// Swiper handlers
function onSwiper(swiper: ISwiper) {
  swiperRef.value = swiper;
}

// Watch for changes in currentComponent to update swiper
watch(currentStepIndex, (newIndex) => {
  if (swiperRef.value) {
    swiperRef.value.slideTo(newIndex);
  }
});

onBeforeMount(() => {
  kioskStatusStore.getKioskStatus();
  initializeOrder();
});
</script>

<template>
  <InactiveWrapper>
    <section
      class="relative flex flex-col items-center justify-center w-full bg-white full-hd"
    >
      <div
        v-if="banners64.length > 0 && !carouselImage"
        class="absolute top-0 z-0"
        :class="{
          'blur-sm brightness-75': route.name === RouteName.CHECK_IN,
        }"
      >
        <ImageCarousel :data-images="banners64" tailwind-classes="full-hd" />
      </div>
      <img
        v-if="kioskImagesStore.customImages64?.mainHeader && carouselImage"
        :src="kioskImagesStore.customImages64.mainHeader"
        alt="Main Header"
        class="top-0 z-0 custom-image"
      />
      <article class="z-10 flex flex-col w-full" v-if="stepsInfo[currentStepIndex]">
        <picture v-if="kioskImagesStore.customImages64?.carousel">
          <KioskImage
            v-show="shouldShowLogo"
            rel="prefetch"
            :src="kioskImagesStore.customImages64.carousel"
            class="logo"
            image-class="max-h-52"
            hide-border
            alt="logo"
          />
        </picture>
        <main
          class="px-16 py-14 text-center mt-[80px] mb-10 bg-gray-50 rounded-cards shadow_container mx-auto w-full transition-all duration-700 ease-in-out"
          :style="{ 'max-width': `${stepsInfo[currentStepIndex].maxWidth ?? '772px'}px` }"
        >
          <!-- Step indicator -->
          <header class="mx-auto mb-10 step-indicator">
            <div class="flex items-center justify-center gap-4">
              <div
                v-for="(step, index) in stepsInfo"
                :key="index"
                v-show="step.enabled"
                class="step-indicator-item"
                :class="{
                  completed: index < currentStepIndex,
                  active: index === currentStepIndex,
                  pending: index > currentStepIndex,
                }"
              />
            </div>
          </header>

          <!-- Content with Swiper for transitions -->
          <Swiper
            :speed="600"
            :effect="'fade'"
            :modules="[EffectFade]"
            :fade-effect="{ crossFade: true }"
            :auto-height="true"
            :allow-touch-move="false"
            :space-between="0"
            @swiper="onSwiper"
            class="w-full custom-swiper"
          >
            <SwiperSlide v-for="(item, index) in componentsToRender" :key="item.key">
              <component
                :is="item.component"
                :key="item.component"
                class="transition-content"
                v-if="index === currentStepIndex"
              />
            </SwiperSlide>
          </Swiper>
        </main>
        <footer v-if="!isFirstElement">
          <button
            id-attr="back-button"
            data-testid="back-button"
            type="button"
            @click="availableNewStepsController.previousStep()"
            class="flex items-center justify-center w-20 h-20 mx-auto border border-gray-300 rounded-full bg-gray-50"
          >
            <span class="text-4xl text-gray-600 icon icon-back-arrow" />
          </button>
        </footer>
        <div id="number-keyboard" />
      </article>
      <img
        v-if="kioskImagesStore.customImages64?.mainFooter && carouselImage"
        :src="kioskImagesStore.customImages64.mainFooter"
        alt="Main Footer"
        class="bottom-0 z-0 custom-image"
      />
    </section>
  </InactiveWrapper>
  <TeleportAnimationModal :show="kioskStatusStore.showUpdatingModal">
    <UpdatingModal />
  </TeleportAnimationModal>
</template>

<style scoped>
.shadow_container {
  box-shadow: 0px 239px 67px 0px rgba(89, 91, 95, 0),
    0px 153px 61px 0px rgba(89, 91, 95, 0.02), 0px 86px 52px 0px rgba(89, 91, 95, 0.05),
    0px 38px 38px 0px rgba(89, 91, 95, 0.09), 0px 10px 21px 0px rgba(89, 91, 95, 0.1);
}

.custom-image {
  @apply h-[200px] w-full object-cover absolute left-1/2 -translate-x-1/2;
}
.step-indicator-item {
  @apply h-6 w-6 rounded-full bg-gray-300 transition-all duration-500 ease-[cubic-bezier(0.4,0,0.2,1)];
  animation: none !important; /* Eliminamos las animaciones anteriores */
}

.step-indicator-item.completed {
  @apply bg-kiosk-primary w-6;
}

.step-indicator-item.active {
  @apply bg-kiosk-primary/35 w-[60px];
}

.step-indicator-item.pending {
  @apply bg-gray-300 w-6;
}

.step-text {
  @apply text-sm text-gray-600;
}

.transition-content {
  overflow: hidden;
}

/* Swiper styles */
.custom-swiper {
  height: auto;
  width: 100%;
}

:deep(.swiper-slide) {
  height: auto;
}

:deep(.swiper-fade .swiper-slide) {
  pointer-events: auto;
}
</style>
