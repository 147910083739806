<script setup lang="ts">
defineProps({
  modalClass: {
    type: String,
    required: false,
    default: '',
  },
  modalHeader: {
    type: Boolean,
    required: false,
    default: true,
  },
  trackInactivity: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const showContent = defineModel({
  type: Boolean,
  required: false,
  default: false,
});

const kioskImagesStore = useKioskImagesStore();
const { customImages64 } = storeToRefs(kioskImagesStore);

onMounted(() => {
  showContent.value = true;
});
</script>
<template>
  <div
    v-if="showContent"
    class="fixed inset-0 z-[53] flex items-center justify-center kiosk-modal"
  >
    <div class="fixed inset-0 flex flex-col items-center justify-center w-full">
      <GlobalInactivityWrapper :track-inactivity="trackInactivity">
        <div :class="[modalClass]" class="relative overflow-hidden">
          <main class="modal-content">
            <img
              v-if="customImages64?.modalHeader && modalHeader"
              :src="customImages64?.modalHeader"
              rel="prefetch"
              alt="Modal Header"
              class="top-0 modal-image"
            />
            <slot name="default" />
            <footer v-if="$slots['footer']" class="relative w-full pt-10">
              <div
                class="w-[calc(100%+100px)] absolute h-[1px] top-0 -left-12 bg-slate-300"
              />
              <slot name="footer" />
            </footer>
          </main>
        </div>
        <div
          class="flex items-center justify-center w-full mt-8 z-10"
          v-if="$slots['close-button']"
        >
          <slot name="close-button" />
        </div>
      </GlobalInactivityWrapper>
    </div>
  </div>
</template>

<style scoped>
.modal-content {
  @apply bg-white pt-28 pb-10 px-[52px] flex flex-col items-center justify-start pointer-events-auto rounded-cards;
}

.modal-image {
  @apply h-20 absolute left-1/2 -translate-x-1/2 object-cover object-center rounded-t-cards;
}
</style>
