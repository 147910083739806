import { ItemDescriptor, QuantifyActionDescriptor } from '@slabcode/hubster-modifiers-utils';
import { addQuantityWithSteal } from '@lib/actions';

export class Quantifiable implements QuantifyActionDescriptor {
  item: ItemDescriptor;

  constructor(item: ItemDescriptor) {
    this.item = item;
  }

  increase() {
    addQuantityWithSteal(this.item, 1);
  }

  decrease() {
    addQuantityWithSteal(this.item, -1);
  }
}
