import { DeepPartial } from '@common/interfaces/deepPartial';
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { HubsterCreateOrderPayload } from '@slabcode/hubster-models/hubster/payloads/manager_orders';
import { v4 as uuidv4 } from 'uuid';
import { OrderCreationDto } from '../interfaces/orderCreationDto';

export const useOrderActionsStore = defineStore('orderActionsStore', () => {
  // DEPENDENCIES
  const route = useRoute();
  const customerStore = useCustomerStoreV2();
  const kioskInfoStore = useKioskInfoStore();
  const orderTypeStore = useOrderTypeStore();
  const cartStore = useCartStoreV2();
  const tableController = useTableController();
  const webhookStore = useWebhookStoreV2();
  const currency = useCurrencyController();
  const inactiveStoreV2 = useInactiveStoreV2();
  const legalPolicies = useLegalPolicies();
  const couponStore = useCouponStore();
  const languageStore = useLanguageStore();
  const clientTypeStore = useClientTypeStore();
  const cartSummary = useCartSummaryStore();
  const { createOrderType: paymentMethod } = storeToRefs(orderTypeStore);

  const { customerDto } = storeToRefs(customerStore);

  // STATE
  const sessionId = ref(uuidv4());

  const orderDto = computed<DeepPartial<HubsterCreateOrderPayload>>(() => ({
    items: cartStore.parseCartItems,
    orderedAt: orderTypeStore.orderedAt,
    customer: customerDto.value,
    orderTotal: cartSummary.totalDto,
    customerPayments: paymentMethod.value === PaymentMethod.UNKNOWN ? undefined : [
      {
        value: cartSummary.totalDto.total,
        paymentMethod: paymentMethod.value,
      },
    ],
    fulfillmentInfo: {
      tableIdentifier: tableController.tableNumber,
      fulfillmentMode: orderTypeStore.fulfillmentMode,
    },
  }));

  const webhookDto = computed(() => {
    const webhook: OrderCreationDto = {
      storeId: kioskInfoStore.storeId,
      sessionId: sessionId.value,
      order: orderDto.value,
      metadata: {
        view: route.name as string,
        language: languageStore.kioskLanguage,
        currency: currency.currencyCode,
        clientType: clientTypeStore.currentClientTypeIndex,
        legal: legalPolicies.acceptedCheckbox,
        alcoholicItems: cartStore.alcoholicItems,
      },
    };

    if (couponStore.couponDto) {
      webhook.metadata.coupon = couponStore.couponDto;
    }

    return webhook;
  });

  async function createOrder(): Promise<void> {
    inactiveStoreV2.pauseChecking();
    await webhookStore.createOrder(webhookDto.value);
  }

  function startOrder() {
    sessionId.value = uuidv4();
  }

  return {
    sessionId,
    webhookDto,
    orderDto,

    createOrder,
    startOrder,
  };
});
