<script lang="ts" setup>
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { Customer } from '@/modules/checkout/interfaces/customer';

const webhookStoreV2 = useWebhookStoreV2();
const customerStore = useCustomerStoreV2();
const clientTypeStore = useClientTypeStore();
const orderTypeStore = useOrderTypeStore();
const cartSummaryStore = useCartSummaryStore();
const { createOrder } = useOrderActionsStore();

const { paymentInProgress, jobResponse } = storeToRefs(webhookStoreV2);
const { customer } = storeToRefs(customerStore);
const { currentClientTypeIndex } = storeToRefs(clientTypeStore);

const orderCustomer = computed((): Customer => ({
  name: customer.value?.name,
  email: customer.value?.email,
  phone: customer.value?.phone,
  taxIdentificationNumber: customer.value?.idNumber,
  clientType: currentClientTypeIndex.value,
}));

function selectPaymentMethod(isCash: boolean) {
  orderTypeStore.setCreateOrderType(isCash ? PaymentMethod.CASH : PaymentMethod.CARD);
  createOrder();
}
</script>

<template>
  <OrderCheckoutModal
    v-if="paymentInProgress || jobResponse"
    :customer="orderCustomer"
    :order-total="cartSummaryStore.totalDto"
    @retry-cash="selectPaymentMethod(true)"
    @retry-card="selectPaymentMethod(false)"
  />
</template>
