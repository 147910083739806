/* eslint-disable no-param-reassign */
import { customizable,
  ItemDescriptor } from '@slabcode/hubster-modifiers-utils';

export function uniqueModifierPatcher(item: ItemDescriptor): void {
  if (item.itemMeta.modifierGroupIds.length > 1) return;
  if (!customizable(item.actions)) return;

  item.actions.customize(({ modifiers, saveChanges }) => {
    const [modifier] = modifiers;
    if (!modifier) return;
    const { dynamicMinimumSelections, dynamicMaximumSelections } = modifier;
    if (
      modifier.items.length === 1
      && modifier.isRequired
      && dynamicMaximumSelections === dynamicMinimumSelections
    ) {
      const [childItem] = modifier.items;
      childItem.quantity = dynamicMinimumSelections;
      childItem.disableAddButton = true;
      childItem.disableSubButton = true;
      item.canBeCustomized = false;
      saveChanges();
    }
  });
}
