/* eslint-disable no-unused-vars */
import { CustomizeActionDescriptor,
  CustomizeTempSubtree,
  ItemDescriptor,
  ModifierFactory,
  QuantifyActionDescriptor } from '@slabcode/hubster-modifiers-utils';
import { addRootQuantity, customizeItem } from '@lib/actions';

export class RootCustomizableQuantifiable
implements QuantifyActionDescriptor, CustomizeActionDescriptor {
  item: ItemDescriptor;

  modifierFactory: ModifierFactory;

  constructor(item: ItemDescriptor, modifierFactory: ModifierFactory) {
    this.item = item;
    this.modifierFactory = modifierFactory;
  }

  increase() {
    addRootQuantity(this.item, 1);
  }

  decrease() {
    addRootQuantity(this.item, -1);
  }

  customize(action: (descriptor: CustomizeTempSubtree) => void): void {
    customizeItem(this.item, this.modifierFactory, action);
  }
}
