<script lang="ts" setup>
const languageStore = useLanguageStore();
const clientTypeStore = useClientTypeStore();
const { clientType, showClientType, clientTypesArray } = storeToRefs(clientTypeStore);
</script>

<template>
  <article
    v-if="showClientType"
    class="flex justify-center gap-10 p-12"
  >
    <template v-for="ct in clientTypesArray" :key="ct.label">
      <label class="flex items-center gap-2" v-if="ct.enabled">
        <input
          type="radio"
          name="regular"
          class="radio checked:bg-kiosk-primary"
          :value="ct"
          v-model="clientType"
        />
        <span class="text-2xl">{{ ct.label[languageStore.kioskLanguage] }}</span>
      </label>
    </template>
  </article>
</template>
