import * as Sentry from '@sentry/vue';
import { OrderCreationDto } from '../../checkout/interfaces/orderCreationDto';

export function useCancelOrderController() {
  const cancelOrder = async (order: OrderCreationDto) => {
    try {
      await requests.post('/orders/cancel', order);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return {
    cancelOrder,
  };
}
