import { IKioskStatusDto } from '@/modules/kiosk-attributes/interfaces/kioskStatus';

export const useKioskStatusStore = defineStore('kioskStatus', () => {
  const errorStore = useErrorStore();
  const kioskInfoStore = useKioskInfoStore();

  const loading = ref(false);
  const pausedBanner = ref<string | null>(null);
  const status = ref(true);
  const autoUpdate = ref(false);
  const showUpdatingModal = ref(false);

  function checkVersion(latestVersion: string) {
    const currentVersion = document.title.split('-')[1].trim();
    if (currentVersion !== latestVersion) {
      showUpdatingModal.value = true;
    }
  }

  function updateVersion() {
    showUpdatingModal.value = false;
    const [, brandName, kioskId] = window.location.pathname.split('/');
    window.location.reload();
    window.location.href = `/${brandName}/${kioskId}`;
  }

  async function getKioskStatus() {
    loading.value = true;
    try {
      const result: IKioskStatusDto = await requests.get(`/${kioskInfoStore.storeId}/kioskStatus`);
      pausedBanner.value = result.status ? null : result.pausedBanner!;
      status.value = result.status;
      autoUpdate.value = result.autoUpdate;
      checkVersion(result.version);
    } catch (err) {
      errorStore.handleAndRedirect(err, 'Error getting kiosk status');
      pausedBanner.value = null;
    } finally {
      loading.value = false;
    }
  }

  return { getKioskStatus, status, pausedBanner, loading, autoUpdate, updateVersion, showUpdatingModal };
});
