import { ActionDescriptor,
  ItemDescriptor } from '@slabcode/hubster-modifiers-utils';
import { CustomizableQuantifiable,
  CustomizableSelectable,
  RootCustomizableQuantifiable,
  Quantifiable,
  RootQuantifiable,
  Selectable } from '@lib/descriptors';
import { modifierFactory } from '@lib/factories';

export function actionsFactory(item: ItemDescriptor): ActionDescriptor {
  if (!item.parent) {
    return item.canBeCustomized
      ? new RootCustomizableQuantifiable(item, modifierFactory)
      : new RootQuantifiable(item);
  }

  if (item.canBeCustomized) {
    return item.hasNumericInput
      ? new CustomizableQuantifiable(item, modifierFactory)
      : new CustomizableSelectable(item, modifierFactory);
  }

  if (!item.canBeCustomized && item.hasNumericInput) {
    return new Quantifiable(item);
  }

  return new Selectable(item);
}
