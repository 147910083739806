<script lang="ts" setup>
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';

const metadataStore = useMetadataStoreV2();
const { priceDecimals } = storeToRefs(metadataStore);
const menuStore = useMenuStore();
const { t } = useI18n();

const props = defineProps({
  product: {
    type: Object as PropType<HubsterMenuPublishItem>,
    required: true,
  },
});

const currencyPrice = computed(() => {
  const overridePrice = menuStore.filteredMenu?.metadata.items[props.product.id]?.overwritePrice;
  const price = formatCurrency(
    'USD',
    overridePrice || props.product.price.amount,
    priceDecimals.value,
  );

  return overridePrice ? `${t('FROM')} ${price}` : price;
});
</script>

<template>
  <span class="product-price">
    {{ currencyPrice }}
  </span>
</template>

<style scoped>
.product-price {
  @apply text-4xl leading-8 tracking-tighter font-normal text-neutral-400 mt-5;
}
</style>
