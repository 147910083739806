import { BrandImagesTypes, ClusterImagesTypes } from '@slabcode/kiosks-core/enums';

export const useKioskImagesStore = defineStore('kioskImages', () => {
  const customImages64 = ref<Record<BrandImagesTypes | ClusterImagesTypes, string> | null>(null);
  const banners64 = ref<string[]>([]);
  // const errorStore = useErrorStore();

  const metadataStoreV2 = useMetadataStoreV2();

  // const urlsToBase64 = async (url: string) => {
  //   try {
  //     const response = await fetch(url);
  //     const blob = await response.blob();
  //     return new Promise<string>((resolve) => {
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         resolve(reader.result as string);
  //       };
  //       reader.readAsDataURL(blob);
  //     });
  //   } catch (err) {
  //     errorStore.handleError(err);

  //     return '';
  //   }
  // };

  const parseCustomImages = async (customImages: Record<BrandImagesTypes | ClusterImagesTypes, string>) => {
    // TODO : Fix Cache problem
    // const base64promises = Object.entries(customImages).map(([key, value]) =>
    //   urlsToBase64(value).then((base64) => [key, base64]));

    // const res = await Promise.all(base64promises);
    // const imagesBase64 = Object.fromEntries(
    //   res,
    // );

    // customImages64.value = imagesBase64;
    customImages64.value = customImages;
  };

  const parseBanners = async (banners: string[]) => {
    // TODO: Fix Cache problem
    // const base64promises = banners.map((banner) => urlsToBase64(banner));
    // const res = await Promise.all(base64promises);
    // const bannersBase64 = res;
    // banners64.value = bannersBase64;

    banners64.value = banners;
  };

  const setup = async () => {
    const { customImages, banners } = metadataStoreV2;
    if (!customImages || banners.length === 0) return;

    // await Promise.all(
    //   [
    //     parseCustomImages(customImages),
    //     parseBanners(banners),
    //   ],
    // );
    parseCustomImages(customImages);
    parseBanners(banners);
  };

  return { setup, banners64, customImages64 };
});
