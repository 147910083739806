<script lang="ts" setup>
import { LanguageLocales } from '../../language/types';

const kioskStatusStore = useKioskStatusStore();
const languageStore = useLanguageStore();
const bannersViewController = useBannersViewController();
const metadataStoreV2 = useMetadataStoreV2();
const legacyController = useCheckInController();

const { isLegacy } = legacyController;
const { isLanguageEditable, languages } = storeToRefs(languageStore);

const startButton = computed(
  () => metadataStoreV2.kioskSettings?.options.startButton ?? true,
);

const handleStartOrder = (langId?: LanguageLocales) => {
  bannersViewController.startOrder(langId);
};
</script>

<template>
  <template v-if="isLanguageEditable && isLegacy">
    <KioskButton
      v-for="languageItem in languages"
      :key="languageItem.id"
      class="menu-button"
      color="primary"
      text-size="large"
      :disabled="kioskStatusStore.loading"
      @click.stop="handleStartOrder(languageItem.id)"
    >
      {{ languageItem.label }}
    </KioskButton>
  </template>

  <template v-if="startButton && !isLanguageEditable">
    <KioskButton
      class="menu-button"
      color="primary"
      text-size="large"
      :disabled="kioskStatusStore.loading"
      @click.stop="handleStartOrder()"
    >
      {{ $t('ORDER_HERE') }}
    </KioskButton>
  </template>
</template>

<style scoped>
.menu-button {
  @apply min-w-[320px];
}
</style>
