import { CssVariables } from '../enums/cssVariables';

export function useCssVariable() {
  function componentToHex(c: number) {
    if (Number.isNaN(c)) {
      console.error('Valor no numérico recibido en componentToHex:', c);
      return '00';
    }
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  }

  // Receive Ex. '182, 22, 54' returns '#b61636'
  function rgbToHex(rgb: string) {
    const components = rgb.split(',').map((color) => parseInt(color.trim(), 10));
    if (components.length < 3 || components.some((component) => Number.isNaN(component))) {
      console.error('El valor RGB no es válido:', rgb);
      return '#b61636'; // o cualquier valor por defecto
    }
    const [r, g, b] = components;
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
  }
  /**
   * Darkens a CSS variable by reducing its brightness.
   * @param cssVariable - The CSS variable to darken.
   * @returns The darkened CSS variable value.
   */
  const changeBrightness = (
    cssVariable: CssVariables,
    amount: -120 | -100 | -50 | -20 | -10 | 0 | 10 | 20 | 50 | 100 | 120,
  ) => {
    const variableHex = getComputedStyle(document.documentElement)
      .getPropertyValue(cssVariable);
    if (variableHex.includes('#')) {
      return newShade(variableHex, amount);
    }
    const hex = rgbToHex(variableHex);
    return newShade(hex, amount);
  };

  return { changeBrightness, rgbToHex };
}
