<script setup lang="ts">
defineProps({
  inputRef: {
    type: Object as PropType<HTMLInputElement>,
    required: true,
  },
  type: {
    type: String as PropType<'number' | 'text' | 'email'>,
    default: 'text',
  },
});

const showKeyboard = defineModel('showKeyboard', { type: Boolean, required: true });
const model = defineModel<string>({ required: true });

defineEmits(['close', 'accept']);
</script>

<template>
  <SlideTransition
    v-if="showKeyboard"
    mode="out-in"
    appear
    class="absolute bottom-0 left-0 w-full"
  >
    <template v-if="showKeyboard">
      <NumbersKeyboard
        v-if="type === 'number'"
        v-model="model"
        :input-ref="inputRef"
        :max-length="20"
        @close="$emit('close')"
        @confirm="$emit('accept')"
      />

      <CustomKeyboard
        v-else
        v-model="model"
        :show-email-suggestions="type === 'email'"
        :input-ref="inputRef"
        @close="$emit('close')"
        @accept="$emit('accept')"
      />
    </template>
  </SlideTransition>
</template>
