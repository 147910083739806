import { customizable, ItemDescriptor, selectable } from '@slabcode/hubster-modifiers-utils';

/**
 * Some day an amazing warrior will have to fix something here. I hope that warrior isn't me
 * But welcome to the jungle.
 */
export function useToggleModifier(parentItem: Ref<ItemDescriptor>) {
  const menuStore = useMenuStore();
  const saveChanges = ref<() => void>();
  const childItem = ref<ItemDescriptor>();

  const childItemHasQuantity = computed(() => {
    if (parentItem.value.quantity === 0) return false;
    if (!childItem.value) return false;
    return childItem.value.quantity > 0;
  });

  const parentInfo = computed(() => {
    const groups = menuStore.filteredMenu?.menu.modifierGroups;
    const items = menuStore.filteredMenu?.menu.items;
    const firstModifier = groups?.[parentItem.value.itemMeta.modifierGroupIds[0]] ?? null;
    const firstChildItem = firstModifier?.itemIds[0] ? items?.[firstModifier.itemIds[0]] : null;

    return {
      isUniqueItem: firstModifier?.itemIds.length === 1,
      isLatestModifier: firstChildItem?.modifierGroupIds?.length === 0,
      isUniqueModifier: parentItem.value.itemMeta.modifierGroupIds.length === 1,
      isFreeItem: firstChildItem?.price.amount === 0,
      isBinary: firstModifier?.maximumSelections === 1,
    };
  });

  /**
   * Display the toggle if the item has only one modifier group,
   * that has only one item, and that has no modifier groups itself.
   * Example: "Sin hielo" from some soda drink.
   */
  const displayChildItemToggle = computed(() => {
    if (parentItem.value.quantity > 1) return false;
    const { isFreeItem, isLatestModifier, isUniqueItem, isUniqueModifier, isBinary } = parentInfo.value;
    return (
      !parentItem.value.hasRequiredModifiers
        && isUniqueModifier
          && isBinary
          && isUniqueItem
          && isLatestModifier
          && isFreeItem
    );
  });

  const childItemName = computed(() => childItem.value?.itemMeta.name ?? '');

  /**
   * Check the parent quantity, because if its 0, should be reset the toggle
   * and not trigger the `customize`
   */
  watch(parentItem.value, () => {
    if (!displayChildItemToggle.value) return;
    if (!customizable(parentItem.value.actions)) return;
    parentItem.value.actions.customize(({ modifiers, saveChanges: save }) => {
      const child = modifiers?.[0]?.items?.[0];
      if (!child) return;

      childItem.value = child;
      saveChanges.value = save;
    });
  }, { immediate: true });

  function toggle() {
    if (!childItem.value) return;
    if (selectable(childItem.value.actions)) {
      childItem.value?.actions.select();
      saveChanges.value?.();
    }
  }

  return {
    childItemHasQuantity,
    displayChildItemToggle,
    childItem,
    childItemName,
    toggle,
  };
}
