<script setup lang="ts">
import { PropType } from 'vue';
import { ItemDescriptor } from '@slabcode/hubster-modifiers-utils';
import { formatCurrency, getProductImage } from '@common/utils';

const props = defineProps({
  item: {
    type: Object as PropType<ItemDescriptor>,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  currency: {
    type: String,
    required: true,
  },
  priceDecimals: {
    type: Number,
    required: true,
  },
});

defineEmits(['add', 'sub', 'edit', 'delete']);

const menuStore = useMenuStore();
const metadataStoreV2 = useMetadataStoreV2();
const { voucherProducts } = storeToRefs(menuStore);
const { brandSettings } = storeToRefs(metadataStoreV2);

const counterStyle = computed(() => brandSettings.value?.buttons.counterStyle);

const productImage = computed(() => getProductImage(props.item.itemMeta.photoIds));

const isVoucherProduct = computed(() =>
  voucherProducts.value.some((p) => p.id === props.item.itemMeta.id));
</script>

<template>
  <div class="py-1 shopping-cart-container">
    <div class="pt-3 shopping-cart-header">
      <div class="flex w-full gap-5">
        <img
          :src="productImage"
          :alt="`${item.itemMeta.name} image`"
          class="object-cover object-center mb-1 w-52 h-52"
        />

        <div class="flex flex-col justify-center gap-3 mx-8">
          <h2 class="mb-2 text-3xl font-bold leading-8">
            {{ item.itemMeta.name }}
          </h2>

          <p class="text-[26px] text-muted">
            {{ description }}
          </p>
        </div>
      </div>

      <span class="price-container">{{
        formatCurrency(currency, item.total, priceDecimals)
      }}</span>
    </div>

    <div class="flex justify-between">
      <div class="flex items-center gap-4 px-4">
        <button
          type="button"
          class="text-3xl font-bold uppercase disabled:text-neutral-300"
          :disabled="!item.canBeCustomized || isVoucherProduct"
          @click="$emit('edit', props.item)"
        >
          {{ $t("EDIT") }}
          <span class="ml-2 icon icon-edit" />
        </button>

        <div class="h-8 w-[2px] bg-neutral-200" />

        <button
          type="button"
          class="flex items-center justify-center text-3xl font-bold uppercase"
          @click="$emit('delete', props.item)"
        >
          {{ $t("DELETE") }}
          <span class="ml-2 icon icon-trash" />
        </button>
      </div>

      <KioskCounterV2
        :disabled="isVoucherProduct"
        :disable-increase="item.disableAddButton"
        :disable-decrease="item.disableSubButton"
        :button-style="counterStyle"
        :quantity="item.quantity"
        @add="$emit('add', props.item)"
        @sub="$emit('sub', props.item)"
      />
    </div>
  </div>
</template>

<style scoped>
.price-container {
  @apply flex items-center justify-center font-bold text-4xl w-52;
}

.icon-button {
  @apply text-2xl font-bold uppercase flex items-center;
}

.shopping-cart-container {
  @apply flex flex-col px-5 border-b border-neutral-200;
}

.shopping-cart-header {
  @apply flex gap-5 justify-between;
}
</style>
