export function useOrderInitialization() {
  const route = useRoute();
  const menuController = useMenuController();
  const languageStore = useLanguageStore();
  const inactiveStoreV2 = useInactiveStoreV2();
  const orderTypeStore = useOrderTypeStore();
  const gtmStore = useGtmStore();
  const orderActionsStore = useOrderActionsStore();

  const { triggerStartOrder } = useGTMEventsComposableV2();

  /**
   * Activate the inactive modal
   */
  async function initializeOrder() {
    // Get menu
    const { storeId } = route.params;
    if (!storeId) return;
    await menuController.getMenu(storeId as string, languageStore.currentLanguage);

    // Generate transactionId
    gtmStore.startOrder();
    triggerStartOrder();
    orderTypeStore.startOrder();
    orderActionsStore.startOrder();
  }

  function cleanupOrder() {
    inactiveStoreV2.pauseChecking();
  }

  onBeforeUnmount(() => cleanupOrder());

  return {
    initializeOrder,
    cleanupOrder,
  };
}
