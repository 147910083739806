<script setup lang="ts">
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const multibrandStore = useMultibrandStore();
const kioskImagesStore = useKioskImagesStore();
const { triggerGoBack } = useGTMEventsComposableV2();

const isNotCategories = computed(() => route.name !== 'OrderCategoriesView');

function goBack() {
  // Trigger GTM event
  triggerGoBack(route.name);

  if (multibrandStore.multibrand) {
    const { backUrl } = multibrandStore;
    window.location.href = backUrl;
    return;
  }

  router.back();
}
</script>

<template>
  <section class="component-wrapper full-hd">
    <img
      v-if="kioskImagesStore.customImages64?.mainHeader"
      :src="kioskImagesStore.customImages64.mainHeader"
      alt="Main Header"
      class="top-0 custom-image"
    />

    <OrderOptions
      :hide-carousel="isNotCategories"
      :back-button-text="t('BACK')"
      @go-back="goBack()"
    >
      <router-view />
    </OrderOptions>

    <img
      v-if="kioskImagesStore.customImages64?.mainFooter"
      :src="kioskImagesStore.customImages64.mainFooter"
      alt="Main Footer"
      class="bottom-0 custom-image"
    />
  </section>
</template>

<style scoped>
.component-wrapper {
  @apply flex flex-col justify-center items-center relative;
}

.custom-image {
  @apply h-[200px] w-full object-cover absolute left-1/2 -translate-x-1/2;
}
</style>
