import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { SalesStatus } from '@slabcode/hubster-models/enums/hubster';
import { AlcoholStatus } from '@/modules/orders/interfaces';
import { GetMenuResponseDto } from '@/modules/orders/interfaces/getMenuResponseDto';

export type MenuState = {
  filteredMenu: GetMenuResponseDto | null;
  currentCategory: HubsterCategory | null;
  voucherProduct: HubsterMenuPublishItem | null;
  hiddenCategories: string[];
  alcohol: AlcoholStatus;
};

export const useMenuStore = defineStore('menu', {
  state: (): MenuState => ({
    filteredMenu: null,
    currentCategory: null,
    voucherProduct: null,
    hiddenCategories: ['COUPON [DO NOT SHOW]'],
    alcohol: { allow: true, checked: false },
  }),

  getters: {
    categories: (state): HubsterCategory[] => {
      const orderTypeStore = useOrderTypeStore();

      if (!state.filteredMenu) return [];

      const categoryIds = state.filteredMenu.metadata.categories.fulfillmentMode[orderTypeStore.safeFulfillmentMode];
      const categories = categoryIds.map((catId) => {
        const result = state.filteredMenu!.menu.categories[catId];
        return {
          ...result,
          name: result.name,
        };
      });

      return categories;
    },

    upSellingBanners: (state): HubsterMenuPublishItem[] => {
      if (!state.filteredMenu) return [];

      const { items } = state.filteredMenu.menu;
      return Object.keys(items)
        .map((itemId) => items[itemId])
        .filter((item) => item.photoIds[1] && item.status.saleStatus === SalesStatus.FOR_SALE);
    },

    products: (state) => {
      const { currentCategory, filteredMenu } = state;

      if (!currentCategory || !filteredMenu) return [];

      return currentCategory.itemIds
        .map((itemId) => filteredMenu.menu.items[itemId])
        .filter((item) => Boolean(item));
    },

    productsCurrency: (state) => {
      if (!state.filteredMenu) return 'USD';

      return 'USD';
    },

    voucherProducts: (state): HubsterMenuPublishItem[] => {
      if (!state.filteredMenu) return [];
      const orderTypeStore = useOrderTypeStore();

      const categoryIds = state.filteredMenu.metadata.categories.fulfillmentMode[orderTypeStore.safeFulfillmentMode];

      const [vouchersCategory] = categoryIds
        .map((c) => state.filteredMenu?.menu.categories[c] as HubsterCategory)
        .filter((c) => state.hiddenCategories.includes(c.name));

      return vouchersCategory?.itemIds.map((itemId) => state.filteredMenu!.menu.items[itemId]) || [];
    },

    metadataMenuInfo: (state) => state.filteredMenu?.metadata,
  },

  actions: {
    setMenuData(data: GetMenuResponseDto): void {
      // Set menu
      this.filteredMenu = data;

      // Set initial category
      const [category] = this.categories;
      this.currentCategory = category;
    },

    /**
     *
     * @param categoryId represent the category to display corresponding items in the cart
     */
    selectCategory(categoryId: string) {
      if (!this.filteredMenu) return;

      this.currentCategory = this.filteredMenu.menu.categories[categoryId];
    },

    /**
     * Define the table number from table view
     * @param {string} tableNumber the table number
     */
    // setTableNumber(tableNumber: string) {
    //   this.$patch({ ...this.$state, tableNumber });
    // },

    /**
     * allow to change fulfillmentMode
     * @param fulfillmentMode new delivery type
     */
    // changeFulfillmentMode(fulfillmentMode: FulfillmentMode) {
    //   this.fulfillmentMode = fulfillmentMode;
    // },

    resetVoucherProduct() {
      this.voucherProduct = null;
    },

    findVoucherProductById(description: string) {
      const index = this.voucherProducts.findIndex((p) => p.description === description);
      // Set value
      this.voucherProduct = (index !== -1) ? this.voucherProducts[index] : null;
    },

    setAlcoholValue(option: Partial<AlcoholStatus>) {
      Object.assign(this.alcohol, option);
    },

    reset() {
      this.$patch({
        filteredMenu: null,
        currentCategory: null,
        voucherProduct: null,
        hiddenCategories: ['COUPON [DO NOT SHOW]'],
        alcohol: { allow: true, checked: false },
      });
    },
  },
});

export default useMenuStore;
