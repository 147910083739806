import { useKioskStatusStore } from '../../kiosk-attributes/composables/useKioskStatus';
import { LanguageLocales } from '../../language/types';

export function useBannersViewController() {
  const maintenanceStore = useMaintenanceStore();
  const kioskStatusStore = useKioskStatusStore();
  const languageStore = useLanguageStore();
  const fulfillmentController = useFulfillmentController();

  async function startOrderLanguage(lang?: LanguageLocales) {
    if (lang) {
      languageStore.setLanguage(lang);
    }

    maintenanceStore.setup();
    if (maintenanceStore.openStore) {
      fulfillmentController.redirectFromWelcome();
    }
  }

  async function startOrder(lang?: LanguageLocales) {
    await kioskStatusStore.getKioskStatus();
    startOrderLanguage(lang);
  }

  return { startOrder, startOrderLanguage };
}
