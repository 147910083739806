import { ItemDescriptor, QuantifyActionDescriptor } from '@slabcode/hubster-modifiers-utils';
import { addRootQuantity } from '@lib/actions';

export class RootQuantifiable
implements QuantifyActionDescriptor {
  item: ItemDescriptor;

  constructor(item: ItemDescriptor) {
    this.item = item;
  }

  increase() {
    addRootQuantity(this.item, 1);
  }

  decrease() {
    addRootQuantity(this.item, -1);
  }
}
