<script lang="ts" setup>
import { selectedItems } from '@common/utils/modifierSelectedItems';

const productCouponStore = useProductCouponStore();
const orderTypeStore = useOrderTypeStore();
const tableController = useTableController();
const currencyController = useCurrencyController();
const cartStoreV2 = useCartStoreV2();
const cartSummary = useCartSummaryStore();
const metadataStoreV2 = useMetadataStoreV2();

const { totalDto } = storeToRefs(cartSummary);

defineProps({
  customerName: {
    type: String,
    required: true,
  },
});

const orderType = computed(() => orderTypeStore.createOrderDescription(tableController.tableNumber));

function format(value: number) {
  return currencyController.formatWithCurrency(value);
}
</script>

<template>
  <section
    class="flex flex-col items-center justify-center pb-32 w-[600px] mx-auto mt-20 relative"
  >
    <div
      class="invoice-border from-kiosk-primary/70 to-kiosk-primary bg-gradient-to-b w-[810px] h-40 left-1/2 rounded-md flex items-center justify-center px-16 absolute z-0 -translate-x-1/2 -top-14"
    >
      <div class="w-full h-20 p-4 dark-border bg-primary-900">
        <div class="w-full h-12 bg-black" />
      </div>
    </div>

    <div class="relative z-10 bg-white summary-card">
      <div class="flex flex-col gap-2">
        <h2 class="text-[32px] leading-6 tracking-tight font-bold">
          {{ customerName }}
        </h2>
        <span class="order-type">{{ orderType }}</span>
      </div>

      <table class="max-h-[650px] overflow-scroll">
        <thead>
          <tr class="table-header">
            <th class="text-start">
              {{ $t('ITEMS') }}
            </th>
            <th class="text-center">
              {{ $t('QUANTITY') }}
            </th>
            <th class="flex justify-end ml-10 max-w-min">
              <span class="text-start">
                {{ $t('PRICE') }}
              </span>
            </th>
          </tr>
        </thead>

        <tbody>
          <!-- COUPON -->
          <tr v-if="productCouponStore.productCoupon" class="table-columns">
            <td class="pt-4 pb-2 text-start">
              {{ productCouponStore.productCoupon.item.name }}
              <small>({{ $t('CUPON') }})</small>
            </td>
            <td class="pt-4 pb-2 text-center">
              1
            </td>
            <td class="pt-4 pb-2 text-end">
              {{ productCouponStore.discountPrice }}
            </td>
          </tr>
          <template :key="item.itemMeta.id" v-for="item in cartStoreV2.cartItems">
            <tr class="table-columns">
              <td class="pt-4 pb-2 text-start">
                {{ item.itemMeta.name }}
              </td>
              <td class="pt-4 pb-2 text-center">
                {{ item.quantity }}
              </td>
              <td class="pt-4 pb-2 text-end">
                {{ format(item.itemMeta.price.amount * item.quantity) }}
              </td>
            </tr>

            <OrderCheckoutRowV2
              v-for="child in selectedItems(item.modifiers)"
              :key="child.itemMeta.id"
              :item="child"
              :is-child-row="false"
              :currency="currencyController.defaultCurrency"
              :decimals="metadataStoreV2.priceDecimals"
              :parent-quantity="item.rxQuantity"
            />
          </template>
        </tbody>
      </table>

      <div class="divider w-[575px] -bottom-7" />
    </div>

    <!-- PriceSummary -->
    <div
      v-if="totalDto"
      class="flex flex-col overflow-hidden summary-total"
      :class="{ '!h-40': totalDto.tax, '': totalDto.discount }"
    >
      <template v-if="totalDto.subtotal !== totalDto.total">
        <div class="flex justify-between w-full h-8 font-normal text-neutral-400">
          <span class="w-5/6 text-center">{{ $t('SUBTOTAL') }}</span>
          <span>{{ format(totalDto.subtotal!) }}</span>
        </div>
      </template>

      <template v-if="totalDto.tax">
        <div class="flex justify-between w-full h-8 font-normal text-neutral-400">
          <span class="w-5/6 text-center">{{ $t('TAXES') }}</span>
          <span>{{ format(totalDto.tax) }}</span>
        </div>
      </template>

      <template v-if="totalDto.discount">
        <div class="flex justify-between w-full h-8 font-normal text-neutral-400">
          <span class="w-5/6 text-center">{{ $t('DISCOUNTS') }}</span>
          <span>-{{ format(totalDto.discount) }}</span>
        </div>
      </template>

      <div class="flex justify-between w-full">
        <span class="w-5/6 text-center">{{ $t('TOTAL') }}</span>
        <span>{{ format(totalDto.total!) }}</span>
      </div>
      <div class="divider w-[590px] -bottom-[-6.25rem]" />
    </div>
  </section>
</template>

<style scoped>
.summary-card {
  @apply shadow-neutral-400/30 shadow-2xl;
  @apply p-12 border border-neutral-500/50 rounded-md rounded-t-none flex flex-col gap-8 w-[600px] min-h-[500px];
}

.order-type {
  @apply text-2xl leading-5 tracking-[0.5px];
}

.summary-total {
  @apply shadow-neutral-400/30 shadow-2xl z-20 bg-white border-b-0 py-3;
  @apply px-12 font-medium border flex justify-center items-center w-[600px] h-fit text-3xl border-t-0 border-neutral-500/50 rounded-t-md;
}

.table-header > th {
  @apply text-2xl font-medium leading-8 tracking-tight text-neutral-400 pb-5;
}

.table-columns > td {
  @apply text-2xl leading-7 tracking-[0.5px];
}

.divider {
  @apply absolute z-40 left-1/2 -translate-x-1/2 border-t-8 border-spacing-0 border-neutral-500/80 border-dotted;
}
</style>
