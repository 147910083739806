<script setup lang="ts">
import { useDateFormat } from '@vueuse/core';
import { useTimeStore } from '@/modules/time/stores/time';

const maintenanceStore = useMaintenanceStore();

const timeStore = useTimeStore();

const { now } = storeToRefs(timeStore);

const time = useDateFormat(now, 'hh:mm AA');
</script>

<template>
  <section class="relative full-hd">
    <div class="clock">
      {{ time }}
    </div>
    <div
      v-if="maintenanceStore.banner"
      class="flex items-center justify-center h-screen"
    >
      <KioskImage
        :src="maintenanceStore.banner"
        alt="Maintenance image"
        hide-border
      />
    </div>
  </section>
</template>
<style scoped>
.clock {
  @apply absolute top-0 right-0 p-4 text-white text-3xl z-10;
}
</style>
