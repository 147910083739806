<script lang="ts" setup>
import { ItemDescriptor } from '@slabcode/hubster-modifiers-utils';
import { PropType } from 'vue';
import { getProductImage } from '@common/utils';

const props = defineProps({
  item: {
    type: Object as PropType<ItemDescriptor>,
    required: true,
  },
});

const productImage = computed(() => getProductImage(props.item.itemMeta.photoIds));
</script>

<template>
  <div
    class="z-10 flex flex-col items-center pb-5 mt-4 border-b shadow-lg shadow-neutral-500/30 current-item border-neutral-200/40"
  >
    <KioskImage
      :src="productImage"
      :alt="item.itemMeta.name"
      hide-border
      class="w-80"
    />

    <h4 class="my-5 text-4xl font-bold product-name">
      {{ item.itemMeta.name }}
    </h4>

    <p class="w-2/3 text-3xl text-center item-description text-neutral-400">
      {{ item.itemMeta.description }}
    </p>
  </div>
</template>
